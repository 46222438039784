import { Grid, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonDevicesMenu: React.FC = () => {
  return (
    <Grid style={{ padding: '10px 23px', borderBottom: 'rgba(50, 50, 50, 0.1)', marginBottom: 5 }} container>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Skeleton animation="wave" variant="text" width={50} />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <Skeleton animation="wave" variant="text" width={120} />
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <Skeleton animation="wave" variant="circular" width={23} height={23} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 10 }}>
        <Skeleton animation="wave" width={290} height={23} />
      </Grid>
    </Grid>
  );
};

export default SkeletonDevicesMenu;
