import { useQuery } from '@apollo/client';
import { Button, Container, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import ReactToPrint from 'react-to-print';
import LoaderCheckAuth from '../../Auth/LoaderCheckAuth';
import { Line } from '../../Interfaces/interfaces';
import CustomDivider from '../RightSide/InfoComponents/CustomDivider';
import SerialNumber from '../RightSide/InfoComponents/SerialNumber';
import BlockPrevInfo from './BlockPrevInfo';
import CurrentLineInfo from './CurrentLineInfo';
import { GET_LINES_INFO } from './graphql/queries';

const LineInfoLog: React.FC = () => {
  const params = useParams();
  const { data, loading } = useQuery<{ line: Line }>(GET_LINES_INFO, {
    variables: { id: params.lineid },
  });

  const [lineInfoCurrent, setLineInfoCurrent] = useState<Line | undefined>();

  useEffect(() => {
    if (data !== undefined) {
      setLineInfoCurrent(data?.line);
    }
  }, [data]);

  const componentRef = useRef<HTMLDivElement>(null);

  if (loading) return <LoaderCheckAuth />;

  return (
    <Container>
      <SerialNumber serialNumber={params.equip} />
      <Grid style={{ marginTop: 30 }}>
        <Grid style={{ marginBottom: 30 }}>
          <Grid style={{ marginBottom: 10 }} container justifyContent="flex-end">
            <ReactToPrint
              trigger={() => {
                return (
                  <Button size="small" variant="outlined">
                    Печать
                  </Button>
                );
              }}
              content={() => componentRef.current}
              documentTitle={`Инфорация по линии ${params.equip!}`}
              pageStyle="print"
            />
          </Grid>
          <Grid ref={componentRef}>
            {lineInfoCurrent !== undefined && <CurrentLineInfo lineInfoCurrent={lineInfoCurrent} />}
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: 15 }} item>
          <CustomDivider text="Выберите за какой промежуток отобразить статусы" />
        </Grid>
        <BlockPrevInfo serialNumber={params.equip} lineId={params.lineid!} />
      </Grid>
    </Container>
  );
};

export default LineInfoLog;
