import { Colors } from '../Const/Colors';
import { Line, STATUS_CODE } from '../Interfaces/interfaces';

enum ERRORS {
  RED = 'CRITICAL',
  YELLOW = 'WARNING',
  GREEN = 'OK',
  ERROR_ERASED = 'ERROR_ERASED',
}

export const getStatusColorBackground = (status: STATUS_CODE): string => {
  if (status.includes(ERRORS.ERROR_ERASED)) return Colors.statusColor.pure.blue;
  else if (status.includes(ERRORS.YELLOW)) return Colors.statusColor.pure.yellow;
  else if (status.includes(ERRORS.RED)) return Colors.statusColor.pure.red;
  return Colors.statusColor.pure.green;
};

export const getStatusColor = (lines: Line[]): string => {
  if (lines.length === 0) return Colors.statusColor.pure.green;
  let ColorStatus = Colors.statusColor.pure.green;

  lines.map(line => {
    if (line.currentStatus === null) return Colors.statusColor.pure.blue;

    return line.currentStatus.status.map(status => {
      if (status.includes(ERRORS.RED)) ColorStatus = Colors.statusColor.pure.red;
      else if (status.includes(ERRORS.YELLOW) && ColorStatus !== Colors.statusColor.pure.red)
        ColorStatus = Colors.statusColor.pure.yellow;
    });
  });
  return ColorStatus;
};
