import { useLazyQuery } from '@apollo/client';
import { Alert, Grid, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Company } from '../../../../../Interfaces/interfaces';
import PortalModal from '../../../../Helper/PortalModal';
import DeleteButton from '../../ComponentsHelper/DeleteButton';
import { GET_ALL_COMPANY_FOR_DELETE } from './queries';
import TableCompanyForDelete from './TableComapnyForDelete';

const DeleteCompany: React.FC = () => {
  const [getAllCompany, { data, loading }] = useLazyQuery<{ companies: Company[] }>(GET_ALL_COMPANY_FOR_DELETE);
  const [companies, setCompanies] = useState<Company[]>();

  useEffect(() => {
    if (!loading) {
      setCompanies(data?.companies);
    }
  }, [data, loading]);

  const [openModalWithAllGroups, setOpenModalWithAllGroups] = useState(false);
  const handlerOpenModalWindow = async (): Promise<void> => {
    setOpenModalWithAllGroups(true);
    await getAllCompany();
  };
  const handlerCloseModalWindow = (): void => setOpenModalWithAllGroups(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const handleOpenSnackbar = (): void => setOpenSnackBar(true);

  return (
    <Grid item>
      <DeleteButton handlerOpenModalWindow={handlerOpenModalWindow}>Удалить компанию</DeleteButton>
      <PortalModal
        open={openModalWithAllGroups}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Просмотр всех компаний"
      >
        {loading ? (
          'loading...'
        ) : (
          <TableCompanyForDelete
            handleOpenSnackbar={handleOpenSnackbar}
            handlerCloseMainWindow={handlerCloseModalWindow}
            currentCompanies={companies}
          />
        )}
      </PortalModal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item>Компания удалена!</Grid>
          </Grid>
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default DeleteCompany;
