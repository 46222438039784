import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';

interface SaveButtonProps {
  clickSaveChanges(): void;
  loading: boolean;
}

const SaveButton: React.FC<SaveButtonProps> = ({ clickSaveChanges, loading }) => {
  return (
    <LoadingButton
      onClick={clickSaveChanges}
      loading={loading}
      loadingPosition="start"
      startIcon={<SaveIcon />}
      variant="contained"
    >
      Сохранить
    </LoadingButton>
  );
};

export default SaveButton;
