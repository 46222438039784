import { gql } from '@apollo/client';

export const GET_ALL_GROUPTS_FOR_MODAL_WINDOW = gql`
  query {
    userGroups {
      id
      name
      alertCondition
    }
  }
`;
