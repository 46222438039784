import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Colors } from '../../Const/Colors';
import { getStatusColor } from '../../Const/getStatusColor';
import { getFullTime } from '../../Const/getTime';
import { Equipment } from '../../Interfaces/interfaces';
import { LinkTo } from '../../Utils/LinkTo';
import CustomTooltip from '../Helper/CustomTooltip';
import LineItem from './LineItem';
interface PropsEquipmentItem {
  equipment: Equipment;
}

const EquipmentItem: React.FC<PropsEquipmentItem> = ({ equipment }) => {
  const StatusColor = getStatusColor(equipment?.lines);
  const params = useParams();

  return (
    <Grid xs item>
      <CustomTooltip placement="right" enterDelay={900} title={equipment.company.name}>
        <Accordion
          expanded={params.equip === equipment.serialNumber}
          style={{
            color: '#222',
            borderRadius: 0,
            borderBottom: '1px solid #E4E4E4',
            width: '100%',
            padding: 0,
            boxShadow: 'none',
            backgroundColor: Colors.menu.menuBackgroundColor,
          }}
        >
          <NavLink style={{ textDecoration: 'none' }} to={`${equipment.serialNumber}`} key={equipment.serialNumber}>
            <AccordionSummary
              style={{
                backgroundColor:
                  params.equip === equipment.serialNumber
                    ? Colors.menu.equipmentFocus
                    : Colors.menu.menuBackgroundColor,
                margin: '5px 0px',
                padding: '9px 15px',
                height: 70,
              }}
              expandIcon={equipment.lines.length !== 0 ? <ExpandMoreIcon /> : null}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container direction="row">
                <Grid
                  alignSelf="center"
                  xs="auto"
                  item
                  style={{
                    backgroundColor: StatusColor,
                    width: Colors.menu.widthStatusBarEquipment,
                    height: Colors.menu.heightStatusBarEquipment,
                    borderRadius: Colors.menu.borderRadiusStatusBar,
                  }}
                />
                <Grid xs item style={{ marginLeft: 10 }}>
                  <Grid justifyContent="space-between" alignItems="center" container item>
                    <Typography variant="subtitle2" sx={{ fontSize: 18 }} color="#222">
                      {equipment.serialNumber}{' '}
                    </Typography>
                    <Typography style={{ marginRight: 10 }} color="GrayText" sx={{ fontSize: 12 }} variant="subtitle2">
                      {getFullTime(equipment.lastSessionDateTime!)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: 270 }}
                      variant="subtitle2"
                      sx={{ fontSize: 14 }}
                      color="#222"
                    >
                      {equipment.company.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  {equipment?.lines?.length !== 0 ? (
                    <Grid
                      item
                      style={{
                        borderRadius: 30,
                        padding: 4,
                        marginLeft: 2.3,
                        width: 20,
                        height: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: StatusColor,
                        position: 'absolute',
                        color: '#eee',
                      }}
                    >
                      {equipment?.lines?.length}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </AccordionSummary>
          </NavLink>
          {equipment?.lines?.length !== 0 ? (
            <AccordionDetails>
              {equipment?.lines?.map((line, index) => {
                return (
                  <LinkTo to={`${equipment.serialNumber}/${line.id}`} key={line.id}>
                    <Grid
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '34px 1fr',
                        backgroundColor:
                          params['*'] === `${equipment.serialNumber}/${line.id}`
                            ? Colors.menu.activeBackgroundLine
                            : '',
                      }}
                      container
                    >
                      <Grid xs="auto" style={{ marginTop: 15, marginLeft: 10 }} item>
                        <Typography variant="subtitle2">№{index + 1}</Typography>
                      </Grid>
                      <LineItem line={line} />
                    </Grid>
                  </LinkTo>
                );
              })}
            </AccordionDetails>
          ) : null}
        </Accordion>
      </CustomTooltip>
    </Grid>
  );
};

export default EquipmentItem;
