import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { getTime } from '../../../Const/getTime';
import { EquipmentAllInfoAboutOne } from '../../../Interfaces/interfaces';
import HideFromClient from '../../../Utils/HideFromClient';
import { DefaultInfoAbout } from './DefaultInfo';

interface EquipmentInfoProps {
  equipment: EquipmentAllInfoAboutOne | undefined;
  handleScrollToMap?(): void;
}

const EquipmentInfo: React.FC<EquipmentInfoProps> = ({ equipment, handleScrollToMap }) => {
  return (
    <Grid item style={{ padding: 10, borderRadius: 10 }}>
      <Divider style={{ width: '100%', marginBottom: 20 }} textAlign="left">
        <Typography variant="h5">Информация о оборудовании</Typography>
      </Divider>
      <Grid>
        <DefaultInfoAbout element={equipment?.company.name} aboutWhatText="Название компании" />
        <DefaultInfoAbout element={equipment?.serialNumber} aboutWhatText="Серийный номер" />
        <DefaultInfoAbout element={equipment?.operator} aboutWhatText="Эксплуатант оборудования" />
        <DefaultInfoAbout element={equipment?.installationAdress} aboutWhatText="Адрес установки" />
        <Grid onClick={handleScrollToMap}>
          <DefaultInfoAbout element={equipment?.coordinates} aboutWhatText="Координаты оборудования" />
        </Grid>
        <DefaultInfoAbout element={equipment?.modification} aboutWhatText="Модификация" />

        {equipment?.acuracyGrade.map((grade, index) => {
          return (
            <DefaultInfoAbout key={index} element={`${grade.name}`} aboutWhatText={`Класс точности №${index + 1}`} />
          );
        })}

        <DefaultInfoAbout element={equipment?.complectation} aboutWhatText="Комплектация" />
        <DefaultInfoAbout element={getTime(equipment?.installationDate)} aboutWhatText="Дата установки" />
        <DefaultInfoAbout element={equipment?.guaranteePeriod} aboutWhatText="Гарантия до" />
        <HideFromClient>
          <DefaultInfoAbout element={equipment?.serviceBasis} aboutWhatText="Основание ТП" />
        </HideFromClient>
        <DefaultInfoAbout element={equipment?.technicalSupportPeriod} aboutWhatText="Техническая поддержка до" />
        <DefaultInfoAbout element={equipment?.checkDate} aboutWhatText="Дата последней поверки" />
        <DefaultInfoAbout element={equipment?.checkSertificateNumber} aboutWhatText="Номер свидетельства" />

        <HideFromClient>
          <DefaultInfoAbout element={equipment?.comments} aboutWhatText="Комментарий" />
          {equipment?.erectors.map((erector, index) => {
            return (
              <DefaultInfoAbout
                key={index}
                element={`${erector.firstname!} ${erector.lastname!}`}
                aboutWhatText={`Монтажник №${index + 1}`}
              />
            );
          })}
        </HideFromClient>
      </Grid>
    </Grid>
  );
};

export default EquipmentInfo;
