import { Grid, Divider, Typography, Chip } from '@mui/material';
import React from 'react';
import { Colors } from '../../../Const/Colors';

interface CustomDivederProps {
  text: string;
}

const CustomDivider: React.FC<CustomDivederProps> = ({ text }) => {
  return (
    <Grid>
      <Divider style={{ width: '100%', marginBottom: 10 }}>
        <Typography style={{ color: Colors.rightSide.emptyInfo }}>
          <Chip label={text} />
        </Typography>
      </Divider>
    </Grid>
  );
};

export default CustomDivider;
