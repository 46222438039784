import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { TabContext, TabPanel } from '@mui/lab';
import { Button, Container, Grid, Tab, Tabs } from '@mui/material';
import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { userContext } from '../../Context/context';
import { ContextUser } from '../../Interfaces/interfaces';
import AddUser from './AddUser/AddUser';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Groput from './GroupAdd/Groput';
import TestSendError from './TestSendError/TestSendError';

const AdminPanel: React.FC = () => {
  const { user } = useContext<ContextUser>(userContext);
  const [value, setValue] = React.useState('1');

  if (user?.role !== 'ADMINISTRATOR') return <Navigate to="/" />;

  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    setValue(newValue);
  };

  const settings = [
    {
      value: '1',
      settingText: 'пользователь',
      icon: <PersonAddAltIcon />,
    },
    {
      value: '2',
      settingText: 'Добавить в группу',
      icon: <SupervisorAccountIcon />,
    },
    {
      value: '3',
      settingText: 'Тестовые ошибки',
      icon: <SupervisorAccountIcon />,
    },
  ];

  return (
    <TabContext value={value}>
      <Grid style={{ marginLeft: 20, marginTop: 10, marginBottom: 10 }}>
        <NavLink style={{ textDecoration: 'none' }} to="/equipment">
          <Button variant="outlined">
            <ArrowBackIosIcon style={{ fontSize: 18 }} />
            Устройства
          </Button>
        </NavLink>
      </Grid>
      <Grid container>
        <Grid item sx={{ bgcolor: 'background.paper', display: 'flex', height: '100vh' }}>
          <Tabs orientation="vertical" value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            {settings.map(setting => {
              return (
                <Tab
                  iconPosition="start"
                  icon={setting.icon}
                  style={{ padding: '20px 30px' }}
                  key={setting.value}
                  label={setting.settingText}
                  value={setting.value}
                />
              );
            })}
          </Tabs>
        </Grid>
        <Grid item xs>
          <Container>
            <TabPanel value="1">
              <AddUser />
            </TabPanel>
            <TabPanel value="2">
              <Groput />
            </TabPanel>
            <TabPanel value="3">
              <TestSendError />
            </TabPanel>
          </Container>
        </Grid>
      </Grid>
    </TabContext>
  );
};

export default AdminPanel;
