import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Equipment } from '../../../../../Interfaces/interfaces';
import PortalModal from '../../../../Helper/PortalModal';
import InfoButton from '../../ComponentsHelper/InfoButton';
import { GET_ALL_EQUIPMENTS_WITH_COMPANY_NAME_FOR_SHOW_IN_TABLE } from './quieries';
import TableWithEquipments from './TableWithEquipements';

const ShowAllEquipments: React.FC = () => {
  const [currentEquipments, setCurrentEquipments] = useState<Equipment[]>();
  const [getAllEquipments, { data, loading }] = useLazyQuery<{ getAllEquipment: Equipment[] }>(
    GET_ALL_EQUIPMENTS_WITH_COMPANY_NAME_FOR_SHOW_IN_TABLE,
  );

  useEffect(() => {
    if (!loading) setCurrentEquipments(data?.getAllEquipment);
  }, [data?.getAllEquipment, loading]);

  const [openModalWithAllGroups, setOpenModalWithAllGroups] = useState(false);
  const handlerOpenModalWindow = async (): Promise<void> => {
    setOpenModalWithAllGroups(true);
    await getAllEquipments();
  };
  const handlerCloseModalWindow = (): void => setOpenModalWithAllGroups(false);

  return (
    <Grid item>
      <InfoButton handlerOpenModalWindow={handlerOpenModalWindow}>Просмотреть все устройства</InfoButton>
      <PortalModal
        open={openModalWithAllGroups}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Просмотр всех устройств"
      >
        {loading ? 'loading...' : <TableWithEquipments currentEquipments={currentEquipments} />}
      </PortalModal>
    </Grid>
  );
};

export default ShowAllEquipments;
