import { Button } from '@mui/material';
import React from 'react';

interface MainButtonSettingProps {
  children: React.ReactChild | React.ReactNode;
  handlerOpenModalWindow(): void;
  Icon?: React.ReactNode;
  color?: 'inherit' | 'info' | 'primary' | 'secondary' | 'success' | 'error' | 'warning';
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'large' | 'medium';
}

const MainButtonSetting: React.FC<MainButtonSettingProps> = ({
  children,
  handlerOpenModalWindow,
  Icon,
  color,
  variant,
  size = 'medium',
}) => {
  return (
    <Button size={size} onClick={handlerOpenModalWindow} startIcon={Icon} color={color} variant={variant}>
      {children}
    </Button>
  );
};

export default MainButtonSetting;
