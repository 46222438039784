import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import CustomSnackBar from '../../../Helper/CustomSnackBar';
import { UpdateBitrixMutation } from './mutatuion';

interface SyncButtonProps {
  serialNumber: string;
  refetch(): void;
}

const SyncButton: React.FC<SyncButtonProps> = ({ serialNumber, refetch }) => {
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const handlerCloseSnackBar = (): void => {
    setOpenSnackBar(false);
    refetch();
  };

  const { updateBitrixFunc, loadingUpdateBitrix } = UpdateBitrixMutation();
  const onClickUpdateBitrix = async (): Promise<void> => {
    try {
      await updateBitrixFunc(serialNumber).catch(e => console.log(e));
    } catch (e) {
      console.log(e);
    }
    setOpenSnackBar(true);
  };
  return (
    <>
      <LoadingButton loading={loadingUpdateBitrix} onClick={onClickUpdateBitrix} size="small" variant="contained">
        Синхронизировать
      </LoadingButton>
      <CustomSnackBar handleCloseSnackBar={handlerCloseSnackBar} openSnackBar={openSnackBar} severity="success">
        Успешная синхронизация информации с битрикс
      </CustomSnackBar>
    </>
  );
};

export default SyncButton;
