import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { getFullTime } from '../../../../Const/getTime';
import { Line } from '../../../../Interfaces/interfaces';
import Statuses from './Statuses';

interface LineProps {
  line: Line;
  index: number;
}

const LineComponent: React.FC<LineProps> = ({ line, index }) => {
  console.log(line);
  const status = line.currentStatus === null ? false : true;
  return (
    <>
      {status && (
        <Paper style={{ cursor: 'pointer' }} elevation={3}>
          <Grid style={{ padding: 10, marginBottom: 15 }}>
            <Grid container justifyContent="space-between">
              <Typography variant="h6">Линия №{index + 1}</Typography>
              <Typography variant="subtitle1">{getFullTime(line.currentStatus.createdAt)}</Typography>
            </Grid>
            <Statuses statuses={line.currentStatus.status} />
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default LineComponent;
