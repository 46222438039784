import { gql } from '@apollo/client';
export const GET_CURRENT_EQUIPEMNTS_FOR_GROUP = gql`
  query ($id: String) {
    userGroup(where: { id: $id }) {
      equipment {
        serialNumber
      }
    }
  }
`;
