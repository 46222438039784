import { useQuery } from '@apollo/client';
import HistoryIcon from '@mui/icons-material/History';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, DialogActions, DialogContentText, Grid, Snackbar, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PortalModal from '../../Helper/PortalModal';
import { DefaultInfoAbout } from '../InfoComponents/DefaultInfo';
import { UpdateApiKeyMutation } from './mutation';
import { GET_API_KEY } from './queries';

interface ApiKeyButtonProps {
  serialNumberEquip: string;
}

const ApiKeyButton: React.FC<ApiKeyButtonProps> = ({ serialNumberEquip }) => {
  const [currentApiKey, setCurrentApiKey] = useState<string | undefined>();
  const { data, loading } = useQuery<{ getAPIKey: string }>(GET_API_KEY, {
    variables: { serialNumber: serialNumberEquip },
  });

  useEffect(() => {
    if (!loading) {
      setCurrentApiKey(data?.getAPIKey);
    }
  }, [loading]);

  const [serialNumberCheck, setSerialNumberCheck] = useState('');

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handlerCloseModalWindow = (): void => setOpenDialog(false);
  const handlerOpenModalWindow = (): void => setOpenDialog(true);

  const [errorChangeApiKey, setErrorChangeApiKey] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const { updateApiKeyFunc, loadingReloadApiKey } = UpdateApiKeyMutation();
  const clickChangeApiKeyApply = async () => {
    if (serialNumberCheck === serialNumberEquip) {
      setErrorChangeApiKey(false);
      const res = await updateApiKeyFunc(serialNumberEquip);
      setCurrentApiKey(res.data?.updateApiKey);
      setOpenSnackBar(true);
      handlerCloseModalWindow();
    } else if (serialNumberCheck !== serialNumberEquip) {
      setErrorChangeApiKey(true);
      setOpenSnackBar(true);
    } else {
      setErrorChangeApiKey(false);
      setOpenSnackBar(true);
    }
    setSerialNumberCheck('');
  };

  return (
    <Grid style={{ marginBottom: 10 }}>
      <PortalModal handlerCloseModalWindow={handlerCloseModalWindow} open={openDialog} dialogTitle="Изменение API_KEY">
        <DialogContentText style={{ marginBottom: 30 }}>
          <Alert style={{ marginBottom: 20 }} severity="error">
            Будьте внимательны при изменении API_KEY устройства!
          </Alert>
          <DefaultInfoAbout hide aboutWhatText="Текущий API_KEY" element={currentApiKey} />
        </DialogContentText>
        <TextField
          style={{ marginBottom: 30 }}
          autoFocus
          id="name"
          label="Укажите сериный номер устройства"
          fullWidth
          variant="outlined"
          value={serialNumberCheck}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setSerialNumberCheck(e.target.value)}
        />
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button onClick={handlerCloseModalWindow}>Отмена</Button>
            <LoadingButton
              color="error"
              variant="contained"
              loading={loadingReloadApiKey}
              startIcon={<HistoryIcon />}
              onClick={clickChangeApiKeyApply}
            >
              Изменить API_KEY
            </LoadingButton>
          </Grid>
        </DialogActions>
      </PortalModal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        {errorChangeApiKey ? (
          <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
            Вы не верно ввели данные!
          </Alert>
        ) : (
          <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
            <Grid container spacing={1}>
              Новый API_KEY сгенерирован!
            </Grid>
          </Alert>
        )}
      </Snackbar>
      <Button onClick={handlerOpenModalWindow} variant="contained" size="small" startIcon={<HistoryIcon />}>
        Сгенерировать новый API_KEY
      </Button>
    </Grid>
  );
};

export default ApiKeyButton;
