import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';

interface PortalAdminGroupSettingsProps {
  children: React.ReactNode | React.ReactChild;
  text: string;
  elevation?: number;
}

const PortalAdminGroupSettings: React.FC<PortalAdminGroupSettingsProps> = ({ children, text, elevation = 2 }) => {
  return (
    <Grid>
      <Paper elevation={elevation} style={{ padding: 30 }}>
        <Typography style={{ marginBottom: 15 }} variant="h5">
          {text}
        </Typography>
        <Grid spacing={2} container>
          {children}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PortalAdminGroupSettings;
