import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { CreateUserInput } from '../../../Interfaces/interfaces';
import { DefaultInfoAbout } from '../../RightSide/InfoComponents/DefaultInfo';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps): JSX.Element => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 2, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme): string => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface CustomizedDialogsProps {
  open: boolean;
  handleCloseDialog(): void;
  userData: CreateUserInput;
  clickAddNewUserSure(): void;
  loading: boolean;
}

const CustomizedDialogs: React.FC<CustomizedDialogsProps> = ({
  open,
  handleCloseDialog,
  userData,
  clickAddNewUserSure,
  loading,
}) => {
  return (
    <div>
      <BootstrapDialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
          Вы уверены что хотите добавить такого пользователя?
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <DefaultInfoAbout aboutWhatText="Фамилия" element={userData.lastname} />
            <DefaultInfoAbout aboutWhatText="Имя" element={userData.firstname} />
            <DefaultInfoAbout aboutWhatText="Логин" element={userData.login} />
            <DefaultInfoAbout aboutWhatText="Почта" element={userData.email} />
            <DefaultInfoAbout aboutWhatText="Пароль" hide element={userData.password} />
            <DefaultInfoAbout aboutWhatText="Роль" element={userData.role} />
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loadingPosition="start"
            startIcon={<AddIcon />}
            loading={loading}
            variant="outlined"
            color="warning"
            onClick={clickAddNewUserSure}
          >
            Добавить
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default CustomizedDialogs;
