import { useLazyQuery } from '@apollo/client';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Company, UserGroup } from '../../../Interfaces/interfaces';
import { companyEmptyObject } from './Groput';
import { GET_ALL_COMPANY_FOR_AUTOCOMPLETE } from './queries';

interface MultipleAutoCompleteCompanyProps {
  valueCurrentCompanies?: (string | Company)[];
  valueCurrentGroupUser?: UserGroup;
  handleCurrentCompanies(_e: React.SyntheticEvent<Element, Event>, value: (string | Company)[]): void;
}

const MultipleAutoCompleteCompany: React.FC<MultipleAutoCompleteCompanyProps> = ({
  valueCurrentCompanies,
  handleCurrentCompanies,
  valueCurrentGroupUser,
}) => {
  const [optionAllCompanies, setOptionAllCompanies] = useState<Company[]>();

  const [getAllCompanies, { data: allCompaniesData, loading: loadingAllCompanies }] = useLazyQuery<{
    companies: Company[];
  }>(GET_ALL_COMPANY_FOR_AUTOCOMPLETE);

  useEffect(() => {
    if (!loadingAllCompanies) setOptionAllCompanies(allCompaniesData?.companies);
  }, [allCompaniesData, loadingAllCompanies]);

  const getAllCompinesOnFocus = async (): Promise<void> => {
    await getAllCompanies();
  };

  const [openCompanies, setOpenCompanies] = useState(false);
  return (
    <Autocomplete
      freeSolo
      size="small"
      multiple
      disabled={valueCurrentGroupUser === undefined ? true : false}
      value={valueCurrentCompanies === undefined ? companyEmptyObject : valueCurrentCompanies}
      onChange={handleCurrentCompanies}
      open={openCompanies}
      onOpen={async (): Promise<void> => {
        setOpenCompanies(true);
        await getAllCompinesOnFocus();
      }}
      onClose={(): void => {
        setOpenCompanies(false);
      }}
      options={optionAllCompanies === undefined ? companyEmptyObject : optionAllCompanies}
      loading={loadingAllCompanies}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      getOptionLabel={(option: Company): string => `${option.name}`}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          label="Выберете компании"
          disabled={valueCurrentGroupUser === undefined ? true : false}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingAllCompanies ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default MultipleAutoCompleteCompany;
