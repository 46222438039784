import { useMutation, gql } from '@apollo/client';
import { ROLE, User } from './../../../../Interfaces/interfaces';
export interface UpdateOneUserRoleInterface {
  role: {
    set: ROLE;
  };
}

export const UpdateOneUserRoleMutation = () => {
  const [updateOneUserRole, { loading }] = useMutation<{ updateOneUser: User }>(gql`
    mutation ($data: UserUpdateInput!, $id: String) {
      updateOneUser(data: $data, where: { id: $id }) {
        id
      }
    }
  `);
  const updateOneUserRoleFunc = (data: UpdateOneUserRoleInterface, id: string): Promise<unknown> =>
    updateOneUserRole({ variables: { data, id } });

  return { updateOneUserRoleFunc, loadingUpdateOneUserRole: loading };
};

export const DeleteOneUserMutation = () => {
  const [deleteOneUser, { loading }] = useMutation<{ deleteOneUser: User }>(gql`
    mutation ($id: String) {
      deleteOneUser(where: { id: $id }) {
        id
      }
    }
  `);
  const deleteOneUserFunc = (id: string): Promise<unknown> => deleteOneUser({ variables: { id } });

  return { deleteOneUserFunc, loadingdeleteOneUser: loading };
};
