import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Line } from '../../../../Interfaces/interfaces';
import { LinkTo } from '../../../../Utils/LinkTo';
import CustomDivder from '../CustomDivider';
import LineComponent from './LineComponent';

interface LinesProps {
  lines: Line[] | undefined;
}

const Lines: React.FC<LinesProps> = ({ lines }) => {
  return (
    <Grid>
      {lines?.length === 0 ? (
        <CustomDivder text="Линий нет" />
      ) : (
        <Grid>
          <Divider style={{ width: '100%', marginBottom: 10 }} textAlign="left">
            <Typography variant="h5">Информация о линиях</Typography>
          </Divider>
          <Grid
            style={{
              padding: 20,
              backgroundColor: 'rgb(231, 235, 240)',
              border: '1px solid #E5E5E5',
              borderRadius: 7,
              overflowY: 'auto',
              maxHeight: 500,
            }}
          >
            {/* <NavLink to="statuses">Логи</NavLink> */}
            {lines?.map((line, index) => {
              return (
                <LinkTo key={line.id} to={line.id}>
                  <LineComponent index={index} line={line} />
                </LinkTo>
              );
            })}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Lines;
