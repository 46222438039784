import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { User } from '../../../../Interfaces/interfaces';
import PortalModal from '../../../Helper/PortalModal';
import InfoButton from '../../GroupAdd/ComponentsHelper/InfoButton';
import { GET_ALL_USERS } from '../queries';
import TableWithAllUsers from './TableWithAllUsers';

const ShowAllUsers: React.FC = () => {
  const [getAllUsers, { data, loading, refetch }] = useLazyQuery<{ users: User[] }>(GET_ALL_USERS);

  const [currentUsers, setCurrentUsers] = useState<User[]>();

  useEffect(() => {
    if (!loading) setCurrentUsers(data?.users);
  }, [data, loading]);

  const [openModalWithAllGroups, setOpenModalWithAllGroups] = useState(false);
  const handlerOpenModalWindow = async (): Promise<void> => {
    setOpenModalWithAllGroups(true);
    await getAllUsers();
  };
  const handlerCloseModalWindow = (): void => setOpenModalWithAllGroups(false);

  return (
    <Grid item>
      <InfoButton handlerOpenModalWindow={handlerOpenModalWindow}>Просмотр всех пользователей</InfoButton>
      <PortalModal
        open={openModalWithAllGroups}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Управление пользователями"
      >
        {loading ? 'loading...' : <TableWithAllUsers refetch={refetch} currentUsers={currentUsers} />}
      </PortalModal>
    </Grid>
  );
};

export default ShowAllUsers;
