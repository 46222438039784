import { Grid, TableCell } from '@mui/material';
import React from 'react';
import { Colors } from '../TableWIthRecordInfo';

interface TableCellRecordProps {
  count?: number;
  value?: number | string;
  SKO?: number | string;
}

const TableCellRecord: React.FC<TableCellRecordProps> = ({ count, value, SKO }) => {
  return (
    <>
      {count && (
        <TableCell>
          <Grid direction="row" wrap="nowrap" container>
            <Grid
              item
              style={{
                padding: 3,
                borderRadius: 3,
                marginRight: 3,
                backgroundColor: Colors.SKO,
                fontSize: 14,
              }}
            >
              D{count}
            </Grid>
            <Grid
              item
              style={{
                padding: 3,
                borderRadius: 3,
                backgroundColor: Colors.value,
                fontSize: 14,
              }}
            >
              SKO{count}
            </Grid>
          </Grid>
        </TableCell>
      )}
      {value && SKO && (
        <TableCell>
          <Grid wrap="nowrap" container>
            <Grid
              item
              style={{
                padding: 3,
                borderRadius: 3,
                marginRight: 3,
                backgroundColor: Colors.SKO,
                fontSize: 14,
              }}
            >
              {SKO}
            </Grid>
            <Grid
              item
              style={{
                padding: 3,
                borderRadius: 3,
                backgroundColor: Colors.value,
                fontSize: 14,
              }}
            >
              {value}
            </Grid>
          </Grid>
        </TableCell>
      )}
    </>
  );
};

export default TableCellRecord;
