import { LazyQueryResult, OperationVariables, useLazyQuery } from '@apollo/client';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Company } from '../../../../Interfaces/interfaces';
import { GET_ALL_COMPANY_FOR_SELECT } from './queries';

interface SelectCompanyProps {
  currentCompany: string;
  handleChangeCurrentCompany(event: SelectChangeEvent): void;
  loadingCurrentCompanyData?: boolean;
}

const SelectCompany: React.FC<SelectCompanyProps> = ({
  currentCompany,
  handleChangeCurrentCompany,
  loadingCurrentCompanyData,
}) => {
  const [getAllCompanies, { data, loading }] = useLazyQuery<{ companies: Company[] }>(GET_ALL_COMPANY_FOR_SELECT);
  const [currentCompanies, setCurrentCompanies] = useState<Company[]>();

  const onFocusCompanySelect = async (): Promise<LazyQueryResult<any, OperationVariables>> => await getAllCompanies();

  useEffect(() => {
    if (!loading) setCurrentCompanies(data?.companies);
  }, [data, loading]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async (): Promise<void> => {
      await getAllCompanies();
    };
    void fetchData();
    return (): void => {
      abortController.abort();
    };
  }, [currentCompany, getAllCompanies, loadingCurrentCompanyData]);

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id="demo-simple-select-label">Компания</InputLabel>
      <Select
        size="small"
        onChange={handleChangeCurrentCompany}
        label="Компания"
        onFocus={onFocusCompanySelect}
        defaultValue=""
        value={loadingCurrentCompanyData ? 'loading..' : currentCompany}
      >
        {loading && currentCompanies === undefined
          ? 'loading...'
          : currentCompanies?.map(company => {
              return (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              );
            })}
      </Select>
    </FormControl>
  );
};

export default SelectCompany;
