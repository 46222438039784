import { gql } from '@apollo/client';

export const GET_ALL_EQUIPMENTS_WITH_COMPANY_NAME_FOR_SHOW_IN_TABLE = gql`
  query {
    getAllEquipment {
      company {
        name
      }
      serialNumber
    }
  }
`;
