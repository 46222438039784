import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import React, { useState } from 'react';
import { Equipment, UserGroup } from '../../../../../Interfaces/interfaces';
import PortalModal from '../../../../Helper/PortalModal';

interface TableWithEquipmentsProps {
  currentEquipments: Equipment[] | undefined;
}

const TableWithEquipments: React.FC<TableWithEquipmentsProps> = ({ currentEquipments }) => {
  function TablePaginationActions(props: TablePaginationActionsProps): JSX.Element {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => onPageChange(event, 0);

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => onPageChange(event, page - 1);

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => onPageChange(event, page + 1);

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void =>
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentEquipments!.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void =>
    setPage(newPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openModalWithAllGroups, setOpenModalWithAllGroups] = useState(false);
  // const handlerOpenModalWindow = (group: UserGroup): void => {
  //   setOpenModalWithAllGroups(true);
  // };
  const handlerCloseModalWindow = (): void => setOpenModalWithAllGroups(false);

  return (
    <div>
      <TableContainer style={{ width: 500 }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Сериный номер</TableCell>
              <TableCell>Название компании</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? currentEquipments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : currentEquipments
            )?.map(equipment => {
              return (
                <TableRow key={equipment.serialNumber} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{equipment.serialNumber}</TableCell>
                  <TableCell>{equipment.company.name}</TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" size="medium">
                      {/* <DeleteIcon onClick={handlerOpenModalWindow} color="error" fontSize="inherit" /> */}
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={currentEquipments === undefined || currentEquipments === null ? 0 : currentEquipments.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <PortalModal
        open={openModalWithAllGroups}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Удаление группы"
      >
        {/* <DeleteEquipment handlerCloseModalWindow={handlerCloseModalWindow} equipment={currentEquipments} /> */}
      </PortalModal>
    </div>
  );
};

export default TableWithEquipments;
