import React from 'react';
import PortalAdminGroupSettings from '../../Helper/PortalAdminGroupSettings';
import AddNewGroupButton from './AddNewGroup/AddNewGroupButton';
import ShowAllGroup from './ShowAllGroup/ShowAllGroup';

const AddNewGroup: React.FC = () => {
  return (
    <PortalAdminGroupSettings elevation={0} text="Работа с группами">
      <AddNewGroupButton />
      <ShowAllGroup />
      {/* <DeleteGroup /> */}
    </PortalAdminGroupSettings>
  );
};

export default AddNewGroup;
