import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import React from 'react';
import { localeMap } from '../../../RightSide/RightSiteMain';

export interface FieldProps {
  value: string | Date | null;
  label: string;
  id: string;
  isDate?: boolean;

  error: boolean;

  onChange(newValue: string | (Date | null), id: string): void;
}

const Field: React.FC<FieldProps> = ({ value, error, onChange, label, id, isDate }) => {
  const handlerChangeField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value, id);
  };

  const handleChange = (newValue: Date | null): void => {
    onChange(newValue, id);
  };

  return (
    <>
      {isDate ? (
        <LocalizationProvider locale={localeMap['ru']} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label={label}
            value={value}
            onChange={handleChange}
            renderInput={(params: any) => {
              <TextField error={error} size="small" style={{ marginBottom: 15 }} fullWidth {...params} />;
            }}
          />
        </LocalizationProvider>
      ) : (
        <TextField
          type={isDate ? 'date' : 'text'}
          focused={isDate ? true : false}
          style={{ marginBottom: 15 }}
          size="small"
          label={label}
          fullWidth
          id={id}
          value={value}
          error={error}
          onChange={handlerChangeField}
        />
      )}
    </>
  );
};

export default Field;
