import { createContext } from 'react';
import { ContextUser } from '../Interfaces/interfaces';

export const userContext = createContext<ContextUser>({
  user: {
    id: 'string',
    firstname: 'string',
    lastname: 'string',
    email: 'string',
    login: 'string',
    role: 'NOTEAUTH',
  },
});
