import { useLazyQuery } from '@apollo/client';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { DateRange, DateRangePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { getFullTime } from '../../Const/getTime';
import { userContext } from '../../Context/context';
import { LineRecord, LineStatusLog } from '../../Interfaces/interfaces';
import { DefaultInfoAbout } from '../RightSide/InfoComponents/DefaultInfo';
import Statuses from '../RightSide/InfoComponents/Lines/Statuses';
import { localeMap } from '../RightSide/RightSiteMain';
import FixButtonLine from './FixButtonLine';
import { GET_PERFIOD_INFO_ALL } from './graphql/queries';
import TableWIthRecordInfo from './TableWIthRecordInfo';

interface BlockPrevInfoProps {
  lineId: string;
  serialNumber: string | undefined;
}

const BlockPrevInfo: React.FC<BlockPrevInfoProps> = ({ lineId, serialNumber }) => {
  const currentTime = new Date();
  const sevenDatsMilliseconds = 6.048 * Math.pow(10, 8);
  const [timePeriod, setTimePeriod] = useState<DateRange<Date>>([
    new Date(Math.abs(Number(currentTime) - sevenDatsMilliseconds)),
    currentTime,
  ]);

  const context = useContext(userContext);

  const [getLineInfoStatusLogs, { data, loading }] = useLazyQuery<{
    getLineStatusPerPeriod: LineStatusLog[];
    getLineRecordPerPeriod: LineRecord[];
  }>(GET_PERFIOD_INFO_ALL);

  const [currentLineStatusLogs, setCurrentLineStatusLogs] = useState<LineStatusLog[]>();
  const [currentLineRecords, setCurrentLineRecords] = useState<LineRecord[]>();
  useEffect(() => {
    setCurrentLineStatusLogs(data?.getLineStatusPerPeriod);
    setCurrentLineRecords(data?.getLineRecordPerPeriod);
  }, [data, loading]);

  const clickShowPeriodButton = async (): Promise<void> => {
    if (timePeriod[0] !== null && timePeriod[1] !== null) {
      const nextDay = timePeriod[1];
      const dateValue = nextDay.getDate() + 1;
      nextDay.setDate(dateValue);
      await getLineInfoStatusLogs({
        variables: {
          data: {
            dateFrom: timePeriod[0],
            dateTo: nextDay,
            relatedLineId: lineId,
          },
          dataRecords: {
            dateFrom: timePeriod[0],
            dateTo: nextDay,
            relatedLineId: lineId,
          },
        },
      });
    }
  };

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
  }

  function TablePaginationActions(props: TablePaginationActionsProps): JSX.Element {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => onPageChange(event, 0);

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => onPageChange(event, page - 1);

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => onPageChange(event, page + 1);

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void =>
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentLineStatusLogs!.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void =>
    setPage(newPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [loadingDownloadLineRecords, setLoadingDownloadLineRecords] = useState(false);
  const ciickDownloadLineRecords = async (): Promise<void> => {
    if (timePeriod[0] !== null && timePeriod[1] !== null) {
      setLoadingDownloadLineRecords(true);
      const nextDay = timePeriod[1];
      const dateValue = nextDay.getDate() + 1;
      nextDay.setDate(dateValue);
      const data = {
        relatedLineId: lineId,
        dateFrom: timePeriod[0],
        dateTo: nextDay,
      };
      const resp = await axios({
        method: 'GET',
        url: `${API_URL}/api/v1/database/lineRecord`,
        params: data,
        responseType: 'blob',
        headers: {
          authorization: localStorage.getItem('token')!,
        },
      });
      const url = window.URL.createObjectURL(
        new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      window.location.href = url;
      setLoadingDownloadLineRecords(false);
    }
  };

  const isSupportOrAdmin = context.user?.role === 'ADMINISTRATOR' || context.user?.role === 'TECHNICAL_SUPPORT';

  return (
    <Grid>
      <Grid spacing={3} justifyContent="flex-start" container item>
        <Grid item>
          <LocalizationProvider locale={localeMap['ru']} dateAdapter={AdapterDateFns}>
            <DateRangePicker
              calendars={1}
              startText="С какого"
              endText="По какое"
              value={timePeriod}
              onChange={(newValue: any): void => {
                setTimePeriod(newValue);
              }}
              renderInput={(startProps: any, endProps: any): JSX.Element => (
                <React.Fragment>
                  <TextField {...startProps} size="small" autoComplete="off" />
                  <Box sx={{ mx: 2 }}> до </Box>
                  <TextField {...endProps} size="small" autoComplete="off" />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid alignSelf="center" item>
          <LoadingButton size="large" loading={loading} variant="outlined" onClick={clickShowPeriodButton}>
            Показать
          </LoadingButton>
        </Grid>
        <Grid alignSelf="center" item>
          <LoadingButton
            size="large"
            loading={loadingDownloadLineRecords}
            color="secondary"
            variant="outlined"
            onClick={ciickDownloadLineRecords}
          >
            Скачать информацию о записях
          </LoadingButton>
        </Grid>
        {isSupportOrAdmin && <FixButtonLine serialNumber={serialNumber} lineId={lineId} />}
      </Grid>
      <Grid style={{ marginBottom: 300 }}>
        {currentLineStatusLogs !== undefined && (
          <Grid style={{ marginTop: 10 }}>
            <DefaultInfoAbout aboutWhatText="За этот период" element={currentLineStatusLogs?.length.toString()} />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Дата</TableCell>
                    <TableCell>Статусы</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? currentLineStatusLogs?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : currentLineStatusLogs
                  )?.map(statuses => {
                    return (
                      <TableRow key={statuses.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{getFullTime(statuses.createdAt)}</TableCell>
                        <TableCell align="left">
                          <Statuses statuses={statuses.status} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={currentLineStatusLogs.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        )}
        {currentLineRecords !== undefined && currentLineRecords.length !== 0 && (
          <TableWIthRecordInfo currentLineRecords={currentLineRecords} />
        )}
      </Grid>
    </Grid>
  );
};

export default BlockPrevInfo;
