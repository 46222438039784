import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Equipment, UserGroup } from '../../../../Interfaces/interfaces';
import AutocompleteUserGroups from '../../../AUTOCOMPLETEUSERGROUP/AutocompeteUserGroups';
import MultipleAutoCompleteEquipments from '../../../AUTOCOMPLETEUSERGROUP/AutoCompleteEquipments';
import CustomSnackBar from '../../../Helper/CustomSnackBar';
import PortalAdminGroupSettings from '../../../Helper/PortalAdminGroupSettings';
import { ConnectInter, UpdateOneUserGroupEquipmentMutation } from './mutatuion';
import { GET_CURRENT_EQUIPEMNTS_FOR_GROUP } from './queries';

const AddEquipmentInGroup: React.FC = () => {
  const [getCurrentEquipmentsForGroup, { data: currentEquipmentsForGroup, loading: loadingCurrentEquipments }] =
    useLazyQuery<{ userGroup: UserGroup }>(GET_CURRENT_EQUIPEMNTS_FOR_GROUP);

  const [valueCurrentGroupUser, setValueCurrentGroupUser] = useState<UserGroup>();
  const handleSelectGroup = async (
    _e: React.SyntheticEvent<Element, Event>,
    value: string | null | UserGroup,
  ): Promise<void> => {
    if (value !== null && typeof value !== 'string') {
      setValueCurrentGroupUser(value);
      await getCurrentEquipmentsForGroup({ variables: { id: value.id } });
    }
  };

  const [currentEquipments, setCurrentEquipments] = useState<(string | Equipment)[]>();
  const handleCurrentEquipments = (_e: React.SyntheticEvent<Element, Event>, value: (string | Equipment)[]): void => {
    setCurrentEquipments(value);
  };

  useEffect(() => {
    if (!loadingCurrentEquipments && currentEquipmentsForGroup !== undefined) {
      setCurrentEquipments(currentEquipmentsForGroup?.userGroup.equipment);
    }
  }, [currentEquipmentsForGroup, loadingCurrentEquipments]);

  const [isWrongSnackBar, setIsWrongSnackBar] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const { updateOneUserGroupFunc, loadingupdateOneUserGroup } = UpdateOneUserGroupEquipmentMutation();

  const onClickSaveSettings = async (): Promise<void> => {
    if (currentEquipments !== undefined && valueCurrentGroupUser !== undefined) {
      const newData: ConnectInter[] = currentEquipments?.map(equipment => {
        return {
          serialNumber:
            typeof equipment !== 'string' && equipment.serialNumber !== undefined ? equipment.serialNumber : '',
        };
      });

      await updateOneUserGroupFunc(
        {
          equipment: {
            connect: newData,
          },
        },
        valueCurrentGroupUser?.id,
      );
      setOpenSnackBar(true);
      setIsWrongSnackBar(false);
    } else {
      setOpenSnackBar(true);
      setIsWrongSnackBar(true);
    }
  };

  const isDisabledEquipmentsInput =
    valueCurrentGroupUser === undefined || valueCurrentGroupUser === null || !valueCurrentGroupUser;

  return (
    <PortalAdminGroupSettings elevation={3} text="Работа с группами">
      <Grid xs item>
        <AutocompleteUserGroups currentGroup={valueCurrentGroupUser} handlerChangeCurrentGroups={handleSelectGroup} />
      </Grid>
      <Grid xs item>
        <MultipleAutoCompleteEquipments
          valueCurrentEquipments={currentEquipments}
          handleCurrentEquipments={handleCurrentEquipments}
          disabled={isDisabledEquipmentsInput}
        />
      </Grid>
      <Grid item>
        <LoadingButton variant="outlined" onClick={onClickSaveSettings} loading={loadingupdateOneUserGroup}>
          Сохранить
        </LoadingButton>
      </Grid>
      {isWrongSnackBar ? (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'error'}>
          Извините что-то пошло не так!
        </CustomSnackBar>
      ) : (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'success'}>
          Изменения сохранены!
        </CustomSnackBar>
      )}
    </PortalAdminGroupSettings>
  );
};

export default AddEquipmentInGroup;
