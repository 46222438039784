import { gql, useMutation } from '@apollo/client';
import { ALERT_CONDITION, Company } from './../../../../Interfaces/interfaces';

export interface CompanyCreateInput {
  name?: textasd;
  createdAt?: textasd;
  phone?: textasd;
  email?: textasd;
  //groups?: UserGroupCreateNestedOneWithoutCompaniesInput;
}

type textasd = string | Date | null;

export interface UserGroupCreateNestedOneWithoutCompaniesInput {
  connect: {
    id: string[];
  };
}

export const CreateOneCompanyMutation = () => {
  const [createOneCompany, { loading }] = useMutation<{ createOneCompany: Company }>(gql`
    mutation ($data: CompanyCreateInput!) {
      createOneCompany(data: $data) {
        id
      }
    }
  `);
  const createOneCompanyFunc = (data: CompanyCreateInput) => createOneCompany({ variables: { data } });

  return { createOneCompanyFunc, loadingCreateOneCompany: loading };
};
