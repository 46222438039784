import { gql, useMutation } from '@apollo/client';

export const UpdateBitrixMutation = () => {
  const [updateDataFromBitrix, { loading }] = useMutation(gql`
    mutation ($serialNumber: String!) {
      updateDataFromBitrix(data: { equipmentSerialNumber: $serialNumber })
    }
  `);
  const updateBitrixFunc = (serialNumber: string): Promise<unknown> =>
    updateDataFromBitrix({ variables: { serialNumber } });
  return { updateBitrixFunc, loadingUpdateBitrix: loading };
};
