import { useLazyQuery } from '@apollo/client';
import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Company } from '../../../../../Interfaces/interfaces';
import { GET_ALL_EQUIPMENTS_WITH_COMPANY_NAME } from './queries';

interface AutoCompleteEquipmentsProps {
  currentEquipment: string;
  handleChangeEquipment(event: SelectChangeEvent): void;
}

const AutoCompleteEquipments: React.FC<AutoCompleteEquipmentsProps> = ({ currentEquipment, handleChangeEquipment }) => {
  const [getAllEquipments, { data, loading }] = useLazyQuery<{ companies: Company[] }>(
    GET_ALL_EQUIPMENTS_WITH_COMPANY_NAME,
  );

  const [currentEquipments, setCurrentEquipments] = useState<Company[]>();

  useEffect(() => {
    if (!loading) setCurrentEquipments(data?.companies);
  }, [data, loading]);

  const handlerFocus = async (): Promise<void> => {
    await getAllEquipments();
  };

  return (
    <FormControl size="small" fullWidth>
      <InputLabel htmlFor="grouped-native-select">Выберите устройство</InputLabel>
      <Select
        onFocus={handlerFocus}
        fullWidth
        native
        size="small"
        label="Выберите устройсто"
        onChange={handleChangeEquipment}
        value={currentEquipment}
      >
        {loading ? (
          'loading...'
        ) : (
          <>
            <option aria-label="None" value="" />
            {currentEquipments?.map(company => {
              return (
                <optgroup key={company.id} label={company.name}>
                  {company.equipment?.map(equip => {
                    return (
                      <option key={equip.serialNumber} value={equip.serialNumber}>
                        {equip.serialNumber}
                      </option>
                    );
                  })}
                </optgroup>
              );
            })}
          </>
        )}
      </Select>
    </FormControl>
  );
};

export default AutoCompleteEquipments;
