import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Menu,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { userContext } from '../../Context/context';
import { ContextUser } from '../../Interfaces/interfaces';
import ButtonLogOut from '../ButtonLogOut';

const AuthUserInfo: React.FC = () => {
  const { user } = useContext<ContextUser>(userContext);
  const params = window.location.href;
  const [lang, setLang] = useState('ru');
  console.log(params);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleChangeSelectLang = (value: any) => {
    setLang(value);
  };

  return (
    <Grid style={{ position: 'absolute', top: 17, right: 25 }}>
      <Tooltip title="Профиль">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Информация о текущем пользователе
          </Typography>
          <Typography variant="h5" component="div">
            {user?.lastname} {user?.firstname}
          </Typography>
          <Typography sx={{ mb: 0.8 }} color="text.secondary">
            <Grid container>
              <Grid item>Роль: </Grid>
              <Grid item style={{ color: 'green', marginLeft: 5 }}>
                {user?.role}
              </Grid>
            </Grid>
          </Typography>
          <Typography variant="body2">Контакты: {user?.email}</Typography>
          <FormControl sx={{ width: '100%', margin: 0, marginTop: 2 }} size="small">
            <InputLabel id="demo-simple-select-label">Язык</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={lang}
              label="Язык"
              onChange={handleChangeSelectLang}
            >
              <MenuItem value={'ru'}>Русский</MenuItem>
              <MenuItem value={20}>Английский</MenuItem>
              <MenuItem value={30}>Английский(Индия)</MenuItem>
              <MenuItem value={30}>Китайский</MenuItem>
            </Select>
          </FormControl>
        </CardContent>
        <CardActions>
          <Grid container justifyContent="space-between">
            <ButtonLogOut />
            {user?.role === 'ADMINISTRATOR' ? (
              <NavLink style={{ textDecoration: 'none' }} to="/admin">
                <Button variant="outlined">Админ панель</Button>
              </NavLink>
            ) : null}
          </Grid>
        </CardActions>
      </Menu>
    </Grid>
  );
};

export default AuthUserInfo;
