import MenuIcon from '@mui/icons-material/Menu';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { Colors } from '../../../Const/Colors';
import DevicesMenu from '../../Menu/DevicesMenu';

const drawerWidth = 360;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function DrawerMenu(props: Props): JSX.Element {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = (): void => setMobileOpen(!mobileOpen);

  const drawer = (
    <Grid
      justifyContent="center"
      style={{
        background: Colors.menu.menuBackgroundColor,
      }}
    >
      <DevicesMenu />
    </Grid>
  );

  const container = window !== undefined ? (): HTMLElement => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', width: { xs: 0, md: drawerWidth } }}>
      <CssBaseline />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          mr: 2,
          display: { xs: 'block', md: 'none' },
          backgroundColor: 'rgba(250, 250, 250, 0.9)',
        }}
        style={{
          position: 'absolute',
          top: 10,
          left: 20,
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box component="nav" sx={{ flexShrink: { sm: 0 } }}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
