//eslint-disable @typescript-eslint/no-unsafe-assignment
import { Grid } from '@mui/material';
import React from 'react';
import { Map, Placemark, YMaps } from 'react-yandex-maps';

interface CoordinatesProps {
  coords: string;
}

const Coordinates = ({ coords }: CoordinatesProps) => {
  const tempCoords = coords.split(',');
  const lon = tempCoords[0];
  const lan = tempCoords[1];
  return (
    <Grid container justifyContent="center">
      <YMaps>
        <Map
          height={450}
          width={550}
          defaultState={{
            center: [Number(lon), Number(lan)],
            zoom: 12,
            controls: ['zoomControl', 'fullscreenControl'],
          }}
          modules={['control.ZoomControl', 'control.FullscreenControl']}
        >
          <Placemark geometry={[Number(lon), Number(lan)]} />
        </Map>
      </YMaps>
    </Grid>
  );
};

export default Coordinates;
