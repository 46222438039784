import { Grid, Typography } from '@mui/material';
import React from 'react';
import { getRusLanguage } from '../../Const/getRusLanguage';
import { getStatusColorBackground } from '../../Const/getStatusColor';
import { STATUS_CODE } from '../../Interfaces/interfaces';

interface PropsStatusLog {
  status: STATUS_CODE;
}

const CurrentStatuses: React.FC<PropsStatusLog> = ({ status }) => {
  return (
    <Grid
      xs
      container
      alignItems="center"
      style={{
        backgroundColor: getStatusColorBackground(status),
        borderRadius: 5,
        color: '#fff',
        padding: 5,
        margin: 5,
      }}
    >
      <Typography noWrap style={{ fontSize: 12 }}>
        {getRusLanguage(status)}
      </Typography>
    </Grid>
  );
};

export default CurrentStatuses;
