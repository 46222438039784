import { useMutation, gql } from '@apollo/client';
import { UserGroup } from '../../../../Interfaces/interfaces';

interface UserGroupWhereUniqueInput {
  id?: string;
  companyId?: string;
}

export const DeleteUserGroupMutation = () => {
  const [deleteUserGroup, { loading }] = useMutation<{ deleteOneUserGroup: UserGroup }>(gql`
    mutation ($id: String) {
      deleteOneUserGroup(where: { id: $id }) {
        id
      }
    }
  `);
  const deleteUserGroupFunc = (id: string | undefined): Promise<unknown> => deleteUserGroup({ variables: { id } });

  return { deleteUserGroupFunc, loadingdeleteGroup: loading };
};
