import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { getFullTime } from '../../Const/getTime';
import { Line } from '../../Interfaces/interfaces';
import { DefaultInfoAbout } from '../RightSide/InfoComponents/DefaultInfo';
import Statuses from '../RightSide/InfoComponents/Lines/Statuses';
import TableCurrentRecords from './TableCurrentRecords';
interface CurrentLineInfoProps {
  lineInfoCurrent: Line | undefined;
}

const CurrentLineInfo: React.FC<CurrentLineInfoProps> = ({ lineInfoCurrent }) => {
  const currentRecords = lineInfoCurrent?.records[lineInfoCurrent.records.length - 1];
  const params = useParams();

  return (
    <Grid
      style={{
        padding: 20,
        backgroundColor: 'rgb(231, 235, 240)',
        border: '1px solid #E5E5E5',
        borderRadius: 7,
      }}
    >
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        Текущее состояние линии
      </Typography>
      <DefaultInfoAbout aboutWhatText="Серийный номер" element={params.equip} />
      <DefaultInfoAbout aboutWhatText="Последнее обновление" element={getFullTime(currentRecords?.createdAt!)} />
      {currentRecords === undefined ? (
        <DefaultInfoAbout aboutWhatText="Информации о датчиках" element="Отсутствует" />
      ) : (
        <Grid style={{ backgroundColor: '1px solid rgba(224, 224, 224, 1)' }}>
          <Typography variant="subtitle2" style={{ marginBottom: 5 }}>
            Информация по датчикам
          </Typography>
          <TableCurrentRecords currentRecords={currentRecords} />
        </Grid>
      )}
      <Grid item>
        <Typography variant="h6" style={{ marginBottom: 10, marginTop: 15 }}>
          Статусы
        </Typography>
        <Grid>
          <Paper elevation={2}>
            {lineInfoCurrent?.currentStatus !== null && <Statuses statuses={lineInfoCurrent?.currentStatus.status!} />}
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CurrentLineInfo;
