import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Colors } from '../../../Const/Colors';

interface PropsSerialNumber {
  serialNumber: string | undefined;
}

const SerialNumber: React.FC<PropsSerialNumber> = ({ serialNumber }) => {
  return (
    <Grid alignItems="center" container style={{ marginTop: 20, borderRadius: 10, marginBottom: 15 }}>
      <Grid item>
        <Typography variant="h5">Серийный номер</Typography>
      </Grid>
      <Grid item style={{ marginLeft: 10 }}>
        <Typography
          variant="h5"
          style={{
            boxSizing: 'border-box',
            border: `5px dashed ${Colors.rightSide.colorOutLineSerialNumber}`,
            padding: '0px 5px',
            color: Colors.rightSide.colorOutLineSerialNumber,
          }}
        >
          {serialNumber}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SerialNumber;
