import { VisibilityOff, Visibility } from '@mui/icons-material';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import React, { useState } from 'react';

interface CustomPasswordProps {
  errorPassword?: boolean;
  onChangeInput(e: React.ChangeEvent<HTMLInputElement>): void;
  value: string;
  title: string;
  id: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
  required?: boolean;
}

const CustomPassword: React.FC<CustomPasswordProps> = ({
  errorPassword,
  onChangeInput,
  value,
  title,
  id,
  size = 'medium',
  disabled = false,
  required = true,
}) => {
  const [passwordShows, setPasswordShows] = useState(false);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => event.preventDefault();
  const clickPasswordShowsButtons = (): void => setPasswordShows(!passwordShows);

  return (
    <FormControl error={errorPassword} size={size} fullWidth variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">{title}</InputLabel>
      <OutlinedInput
        fullWidth
        disabled={disabled}
        size={size}
        id={id}
        autoComplete="off"
        required={required}
        error={errorPassword}
        onChange={onChangeInput}
        value={value}
        type={passwordShows ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={clickPasswordShowsButtons}
              onMouseDown={handleMouseDownPassword}
              aria-label="toggle password visibility"
              id="password"
              edge="end"
            >
              {passwordShows ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={title}
      />
    </FormControl>
  );
};

export default CustomPassword;
