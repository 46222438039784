import { useQuery } from '@apollo/client';
import { DateRange, DateRangePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, ButtonGroup, Grid, TextField, Typography } from '@mui/material';
import axios from 'axios';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import ReactToPrint from 'react-to-print';
import { API_URL } from '../../config';
import { Colors } from '../../Const/Colors';
import { userContext } from '../../Context/context';
import { GET_ONE_EQUIPMENT } from '../../graphql/queries/queries';
import { EquipmentAllInfoAboutOne, GetOneEquipment } from '../../Interfaces/interfaces';
import { AlertCenter } from '../../Utils/AlertCenter';
import HideFromClient from '../../Utils/HideFromClient';
import PortalAdminGroupSettings from '../Helper/PortalAdminGroupSettings';
import ApiKeyButton from './ApiKeyRegenerate/ApiKeyButton';
import Coordinates from './InfoComponents/Coordinates';
import EquipmentInfo from './InfoComponents/EquipmentInfo';
import Lines from './InfoComponents/Lines/Lines';
import SerialNumber from './InfoComponents/SerialNumber';
import SyncButton from './InfoComponents/SyncButton/SyncButton';
import LoaderRightSide from './LoaderRightSide';

export const maskMap = {
  fr: '__/__/____',
  en: '__/__/____',
  ru: '__.__.____',
  de: '__.__.____',
};

export const localeMap = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
  de: deLocale,
};

const RightSideMain: React.FC = () => {
  const params = useParams();
  const context = useContext(userContext);
  const { data, loading, refetch } = useQuery<GetOneEquipment>(GET_ONE_EQUIPMENT, {
    variables: { serialNumber: params.equip },
  });

  document.title = `STATERA - ${params.equip!}`;

  const [equipment, setEquipment] = useState<EquipmentAllInfoAboutOne | undefined>(data?.getOneEquipment);
  useEffect(() => {
    if (data !== undefined) {
      setEquipment(data?.getOneEquipment);
    }
  }, [data]);
  const haveDevice = equipment === undefined || equipment === null ? false : true;

  const componentRef = useRef<HTMLDivElement>(null);

  const currentTime = new Date();
  const sevenDatsMilliseconds = 6.048 * Math.pow(10, 8);
  const [timePeriod, setTimePeriod] = useState<DateRange<Date>>([
    new Date(Math.abs(Number(currentTime) - sevenDatsMilliseconds)),
    currentTime,
  ]);

  const [loadingDonwload, setLoadingDonwload] = useState(false);
  const clickShowMoreButotn = async (): Promise<void> => {
    const data = {
      relatedLineId: equipment?.serialNumber,
      dateFrom: timePeriod[0],
      dateTo: timePeriod[1],
    };

    if (timePeriod[0] !== null && timePeriod[1] !== null) {
      setLoadingDonwload(true);
      const resp = await axios({
        method: 'GET',
        url: `${API_URL}/api/v1/database/lineInfo`,
        params: data,
        responseType: 'blob',
        headers: {
          authorization: localStorage.getItem('token')!,
        },
      });

      const url = window.URL.createObjectURL(
        new Blob([resp.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );
      window.location.href = url;
      setLoadingDonwload(false);
    }
  };

  const haveCoordinates = equipment?.coordinates !== null && equipment?.coordinates !== undefined;

  const [locale, setLocale] = React.useState<keyof typeof maskMap>('ru');

  const mapForScroll = useRef<null | HTMLDivElement>(null);
  const handleScrollToMap = (): void => {
    if (mapForScroll && mapForScroll.current /* + other conditions */)
      mapForScroll.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  if (loading) return <LoaderRightSide />;
  return (
    <>
      {haveDevice ? (
        <Grid item>
          <SerialNumber serialNumber={equipment?.serialNumber} />
          <Grid style={{ marginTop: 20 }} container justifyContent="space-between">
            {context.user?.role === 'ADMINISTRATOR' && <ApiKeyButton serialNumberEquip={equipment?.serialNumber!} />}
            <HideFromClient>
              <Grid style={{ marginLeft: 10, marginRight: 10 }} item>
                <Grid container spacing={2}>
                  <Grid item>
                    <ButtonGroup size="small">
                      <Button
                        rel="noreferrer"
                        target="_blank"
                        href={`https://statera.bitrix24.ru/bizproc/processes/34/element/0/${equipment?.bitrixId!}/`}
                      >
                        Ссылка на оборудование
                      </Button>
                      <Button
                        rel="noreferrer"
                        href={`https://statera.bitrix24.ru/crm/company/details/${equipment?.company.bitrixID!}/`}
                        target="_blank"
                      >
                        Cсылка на компанию
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid item>
                    <SyncButton serialNumber={equipment?.serialNumber!} refetch={refetch} />
                  </Grid>
                </Grid>
              </Grid>
            </HideFromClient>
            <Grid item>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button size="small" variant="outlined">
                      Печать
                    </Button>
                  );
                }}
                content={() => componentRef.current}
                pageStyle="print"
              />
            </Grid>
          </Grid>
          <Grid style={{ marginTop: 10 }} ref={componentRef}>
            <EquipmentInfo equipment={equipment} handleScrollToMap={handleScrollToMap} />
            <Lines lines={equipment?.lines} />
          </Grid>
          <Grid style={{ marginTop: 40, marginBottom: 50 }} spacing={3} justifyContent="flex-start" container item>
            <Grid item>
              <LocalizationProvider locale={localeMap[locale]} dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  calendars={1}
                  startText="С какого"
                  endText="По какое"
                  value={timePeriod}
                  onChange={(newValue: any): void => {
                    setTimePeriod(newValue);
                  }}
                  renderInput={(startProps: any, endProps: any): JSX.Element => (
                    <React.Fragment>
                      <TextField {...startProps} autoComplete="off" />
                      <Box sx={{ mx: 2 }}> до </Box>
                      <TextField {...endProps} autoComplete="off" />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid alignSelf="center" item>
              <LoadingButton
                size="large"
                loading={loadingDonwload}
                color="secondary"
                variant="outlined"
                onClick={clickShowMoreButotn}
              >
                Скачать информацию по линиям
              </LoadingButton>
            </Grid>
          </Grid>
          {haveCoordinates && (
            <Grid ref={mapForScroll} style={{ marginTop: 20, marginBottom: 100, width: '100%' }}>
              <PortalAdminGroupSettings text="Местоположение оборудования">
                <Typography
                  color={Colors.rightSide.colorInfoAbout}
                  variant="subtitle2"
                  style={{ marginBottom: 10, marginLeft: 17 }}
                >
                  {equipment.coordinates}
                </Typography>
                <Coordinates coords={equipment?.coordinates!} />
              </PortalAdminGroupSettings>
            </Grid>
          )}
        </Grid>
      ) : localStorage.getItem('token') === null ? (
        <Navigate to="/" />
      ) : (
        <AlertCenter severity="warning">
          Возможно вы случайно выбрали не существующию линию, для этого просто выберите другую в меню
        </AlertCenter>
      )}
    </>
  );
};

export default RightSideMain;
