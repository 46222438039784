import { useMutation, gql } from '@apollo/client';
import { UserGroup } from '../../../../Interfaces/interfaces';

export interface UpdateOneUserGroupInteface {
  equipment: {
    connect: ConnectInter[];
  };
}

export interface ConnectInter {
  serialNumber: string;
}

export const UpdateOneUserGroupEquipmentMutation = () => {
  const [updateOneUserGroup, { loading }] = useMutation<{ updateOneUserGroup: UserGroup }>(gql`
    mutation ($data: UserGroupUpdateInput!, $id: String) {
      updateOneUserGroup(data: $data, where: { id: $id }) {
        id
      }
    }
  `);
  const updateOneUserGroupFunc = (data: UpdateOneUserGroupInteface, id: string | undefined): Promise<unknown> =>
    updateOneUserGroup({ variables: { data, id } });

  return { updateOneUserGroupFunc, loadingupdateOneUserGroup: loading };
};
