import { IconButton, MenuItem, Select, SelectChangeEvent, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ALERT_CONDITION, UserGroup } from '../../../../Interfaces/interfaces';
import DeleteGroupModal from '../DeleteGroup/DeleteGroupModal';
import { UdapteNotificationGroup, UpdateInputNotification } from './mutation';
import DeleteIcon from '@mui/icons-material/Delete';
import PortalModal from '../../../Helper/PortalModal';

export const arrayCondition = [
  {
    value: 'ON_ERROR',
    title: 'Оповещать об ошибках',
  },
  {
    value: 'ON_WARNING',
    title: 'Оповещать о предупреждениях',
  },
  {
    value: 'ALERT_OFF',
    title: 'Без оповещений',
  },
];

interface OneGroupTableProps {
  group: UserGroup;
  handlerCloseMainWindow(): void;
  handleOpenSnackbar(): void;
}

const OneGroupTable: React.FC<OneGroupTableProps> = ({ group, handleOpenSnackbar, handlerCloseMainWindow }) => {
  const [notificationStatus, setNotificationStatus] = useState(group.alertCondition);

  useEffect(() => {
    setNotificationStatus(group.alertCondition);
  }, [group]);

  const { udapteNotificationGroupFunc, loadingUdapteNotificationGroup } = UdapteNotificationGroup();
  const handlerChangeStatusNotification = async (event: SelectChangeEvent): Promise<void> => {
    setNotificationStatus(event.target.value as ALERT_CONDITION);

    const newUpdateNotification: UpdateInputNotification = {
      alertCondition: { set: event.target.value as ALERT_CONDITION },
    };

    await udapteNotificationGroupFunc(newUpdateNotification, group.id);
  };

  const [currentGroupForDelete, setCurrentGroupForDelete] = useState<UserGroup>();
  const [openModalWithAllGroups, setOpenModalWithAllGroups] = useState(false);
  const handlerOpenModalWindow = (group: UserGroup) => {
    setOpenModalWithAllGroups(true);
    setCurrentGroupForDelete(group);
  };
  const handlerCloseModalWindow = (): void => setOpenModalWithAllGroups(false);
  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align="left">{group.name}</TableCell>
        <TableCell style={{ maxWidth: 145 }} align="left">
          <Select
            fullWidth
            disabled={loadingUdapteNotificationGroup}
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={notificationStatus}
            onChange={(event: SelectChangeEvent): Promise<void> => handlerChangeStatusNotification(event)}
          >
            {arrayCondition.map((cond, index) => {
              return (
                <MenuItem key={index} value={cond.value}>
                  {cond.title}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
        <TableCell align="left">
          <IconButton onClick={(): void => handlerOpenModalWindow(group)} color="error" aria-label="Удалить группу">
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <PortalModal
        open={openModalWithAllGroups}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Удаление группы"
      >
        <DeleteGroupModal
          handleOpenSnackBar={handleOpenSnackbar}
          handlerCloseModalWindow={handlerCloseModalWindow}
          group={currentGroupForDelete!}
          handlerCloseMainWindow={handlerCloseMainWindow}
        />
      </PortalModal>
    </>
  );
};

export default OneGroupTable;
