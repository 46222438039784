import { Button } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import MainButtonSetting from './MainButtonSetting';

interface AddButtonProps {
  children: React.ReactChild | React.ReactNode;
  handlerOpenModalWindow(): void;
}

const AddButton: React.FC<AddButtonProps> = ({ children, handlerOpenModalWindow }) => {
  return (
    <MainButtonSetting handlerOpenModalWindow={handlerOpenModalWindow} Icon={<AddIcon />} variant="outlined">
      {children}
    </MainButtonSetting>
    // <Button onClick={handlerOpenModalWindow} startIcon={<AddIcon />} variant="outlined">
    //   {children}
    // </Button>
  );
};

export default AddButton;
