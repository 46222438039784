import { gql } from '@apollo/client';

export const GET_ALL_EQUIPMENTS_WITH_COMPANY_NAME = gql`
  query {
    companies {
      name
      id
      equipment {
        company {
          name
        }
        serialNumber
      }
    }
  }
`;

export const GET_CURRENT_COMPANY_FOR_EQUIPMENT = gql`
  query ($serialNumber: String!) {
    getOneEquipment(data: { equipmentSerialNumber: $serialNumber }) {
      serialNumber
      company {
        name
        id
      }
    }
  }
`;
