import { gql, useMutation } from '@apollo/client';
import { ALERT_CONDITION, UserGroup } from '../../../../Interfaces/interfaces';

export interface CreateOneUserGroup {
  name: string;
  alertCondition: ALERT_CONDITION;
}

export const CreateOneUserGroupMutation = () => {
  const [createOneUserGroup, { loading }] = useMutation<{ createOneUserGroup: UserGroup }>(gql`
    mutation ($data: UserGroupCreateInput!) {
      createOneUserGroup(data: $data) {
        id
      }
    }
  `);
  const createOneUserGroupFunc = (data: CreateOneUserGroup) => createOneUserGroup({ variables: { data } });

  return { createOneUserGroupFunc, loadingCreateUserGroup: loading };
};
