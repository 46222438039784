import { gql, useMutation } from '@apollo/client';

export const UpdateApiKeyMutation = () => {
  const [updateApiKey, { loading }] = useMutation<{ updateApiKey: string }>(gql`
    mutation ($serialNumber: String!) {
      updateApiKey(data: { equipmentSerialNumber: $serialNumber })
    }
  `);
  const updateApiKeyFunc = (serialNumber: string) => updateApiKey({ variables: { serialNumber } });
  return { updateApiKeyFunc, loadingReloadApiKey: loading };
};
