import { useLazyQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GET_ALL_EQUIPMENTS } from '../../graphql/queries/queries';
import { Equipment } from '../../Interfaces/interfaces';

interface MultipleAutoCompleteEquipmentsProps {
  valueCurrentEquipments?: (string | Equipment)[];
  handleCurrentEquipments(_e: React.SyntheticEvent<Element, Event>, value: (string | Equipment)[]): void;
  disabled?: boolean;
}

const MultipleAutoCompleteEquipments: React.FC<MultipleAutoCompleteEquipmentsProps> = ({
  valueCurrentEquipments,
  handleCurrentEquipments,
  disabled,
}) => {
  const [optionAllEquipments, setOptionAllEquipments] = useState<Equipment[]>();

  const [getAllEquipments, { data: allEquipmentsData, loading: loadingAllEquipments }] = useLazyQuery<{
    getAllEquipment: Equipment[];
  }>(GET_ALL_EQUIPMENTS);

  useEffect(() => {
    if (!loadingAllEquipments) setOptionAllEquipments(allEquipmentsData?.getAllEquipment);
  }, [allEquipmentsData, loadingAllEquipments]);

  const getAllCompinesOnFocus = async (): Promise<void> => {
    await getAllEquipments();
  };

  const [openEquipments, setOpenEquipments] = useState(false);

  const equipemntsEmpty: Equipment[] = [
    {
      serialNumber: '',
      company: {
        id: '',
        createdAt: new Date(0),
        updatedAt: new Date(0),
        name: '',
        phone: '',
        email: '',
      },
      lines: [],
    },
  ];

  return (
    <Autocomplete
      freeSolo
      size="small"
      multiple
      disabled={disabled}
      value={valueCurrentEquipments === undefined ? equipemntsEmpty : valueCurrentEquipments}
      onChange={handleCurrentEquipments}
      open={openEquipments}
      onOpen={async (): Promise<void> => {
        setOpenEquipments(true);
        await getAllCompinesOnFocus();
      }}
      onClose={(): void => {
        setOpenEquipments(false);
      }}
      options={optionAllEquipments === undefined ? equipemntsEmpty : optionAllEquipments}
      loading={loadingAllEquipments}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      getOptionLabel={(option: Equipment): string => `${option.serialNumber}`}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          disabled={disabled}
          label="Выберете устройства"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingAllEquipments ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default MultipleAutoCompleteEquipments;
