import { gql } from '@apollo/client';
export const GET_LINES_INFO = gql`
  query ($id: String) {
    line(where: { id: $id }) {
      records {
        id
        createdAt
        SKO1
        SKO2
        SKO3
        SKO4
        SKO5
        SKO6
        SKO7
        SKO8
        value1
        value2
        value3
        value4
        value5
        value6
        value7
        value8
      }
      info {
        attachments
        createdAt
        message
      }
      currentStatus {
        id
        status
        createdAt
      }
    }
  }
`;

export const GET_PERIOD_LINE_INFO = gql`
  query ($data: GetLineRecordPerPeriodArgs!) {
    getLineRecordPerPeriod(data: $data) {
      id
      relatedLine {
        statusLog {
          id
          status
          createdAt
        }
      }
      rails {
        id
        detectorsValues
      }
      SKO
      min
      max
    }
  }
`;

export const GET_RERIOD_LINE_INFO_STATUS = gql`
  query ($data: GetLineStatusPerPeriodArgs!) {
    getLineStatusPerPeriod(data: $data) {
      status
      createdAt
      id
    }
  }
`;

export const GET_PERFIOD_INFO_ALL = gql`
  query ($data: GetLineStatusPerPeriodArgs!, $dataRecords: GetLineRecordPerPeriodArgs!) {
    getLineStatusPerPeriod(data: $data) {
      id
      status
      createdAt
    }
    getLineRecordPerPeriod(data: $dataRecords) {
      id
      createdAt
      SKO1
      SKO2
      SKO3
      SKO4
      SKO5
      SKO6
      SKO7
      SKO8
      value1
      value2
      value3
      value4
      value5
      value6
      value7
      value8
    }
  }
`;
