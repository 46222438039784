import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import React, { useState } from 'react';
import { getFullTime } from '../../Const/getTime';
import { LineRecord } from '../../Interfaces/interfaces';
import { DefaultInfoAbout } from '../RightSide/InfoComponents/DefaultInfo';
import TableCellRecord from './TableCellRecord/TableCellRecord';

export const Colors = {
  SKO: '#E9F7EF',
  value: '#FEF5E7',
  date: '#F4ECF7',
};

interface TableWIthRecordInfoProps {
  currentLineRecords: LineRecord[];
}

const TableWIthRecordInfo: React.FC<TableWIthRecordInfoProps> = ({ currentLineRecords }) => {
  function TablePaginationActions(props: TablePaginationActionsProps): JSX.Element {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => onPageChange(event, 0);
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => onPageChange(event, page - 1);
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => onPageChange(event, page + 1);
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void =>
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentLineRecords.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void =>
    setPage(newPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log(currentLineRecords);

  return (
    <Grid style={{ marginTop: 10, marginBottom: 300 }}>
      <DefaultInfoAbout aboutWhatText="За этот период" element={currentLineRecords?.length.toString()} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 250 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCellRecord count={1} />
              <TableCellRecord count={2} />
              <TableCellRecord count={3} />
              <TableCellRecord count={4} />
              <TableCellRecord count={5} />
              <TableCellRecord count={6} />
              <TableCellRecord count={7} />
              <TableCellRecord count={8} />
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? currentLineRecords?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : currentLineRecords
            )?.map(record => {
              return (
                <TableRow key={record.id} style={{ height: 53 * emptyRows }}>
                  <TableCell>{getFullTime(record.createdAt)}</TableCell>
                  <TableCellRecord value={record.value1} SKO={record.SKO1} />
                  <TableCellRecord value={record.value2} SKO={record.SKO2} />
                  <TableCellRecord value={record.value3} SKO={record.SKO3} />
                  <TableCellRecord value={record.value4} SKO={record.SKO4} />
                  <TableCellRecord value={record.value5} SKO={record.SKO5} />
                  <TableCellRecord value={record.value6} SKO={record.SKO6} />
                  <TableCellRecord value={record.value7} SKO={record.SKO7} />
                  <TableCellRecord value={record.value8} SKO={record.SKO8} />
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                count={currentLineRecords.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default TableWIthRecordInfo;
