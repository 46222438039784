import { Grid } from '@mui/material';
import React from 'react';
import { Outlet, useParams } from 'react-router';
import { Colors } from '../../Const/Colors';
import { AlertCenter } from '../../Utils/AlertCenter';
import DrawerMenu from '../RightSide/Drawer/DrawerMenu';

const Main: React.FC = () => {
  const params = useParams();

  const pickAnyEquip = params['*'] !== '' ? false : true;
  return (
    <Grid container style={{ backgroundColor: Colors.mainBackroundColor }}>
      <DrawerMenu />
      <Grid xs container item justifyContent="center" style={{ padding: '0px 10px', width: '90%' }}>
        <Outlet />
        {pickAnyEquip ? <AlertCenter severity="info">Выберите одно из устройств</AlertCenter> : null}
      </Grid>
    </Grid>
  );
};

export default Main;
