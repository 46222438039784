import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import React from 'react';
import MainButtonSetting from './MainButtonSetting';

interface DeleteButtonProps {
  handlerOpenModalWindow(): void;
  children: React.ReactChild | React.ReactNode;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ handlerOpenModalWindow, children }) => {
  return (
    <MainButtonSetting
      handlerOpenModalWindow={handlerOpenModalWindow}
      Icon={<RestoreFromTrashIcon />}
      color="warning"
      variant="outlined"
    >
      {children}
    </MainButtonSetting>
  );
};

export default DeleteButton;
