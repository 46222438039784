//eslint-disable @typescript-eslint/no-unsafe-assignment
/* eslint-disable @typescript-eslint/no-unsafe-member-access*/

import { useQuery } from '@apollo/client';
import React from 'react';
import { Navigate } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoaderCheckAuth from '../Auth/LoaderCheckAuth';
import Login from '../Auth/Login';
import AdminPanel from '../Components/Admin/AdminPanel';
import AuthUserInfo from '../Components/AuthUserInfo/AuthUserInfo';
import LineInfoLog from '../Components/LineLog/LineInfoLog';
import Main from '../Components/Main/Main';
import RightSideMain from '../Components/RightSide/RightSiteMain';
import { userContext } from '../Context/context';
import { ME_QUERY } from '../graphql/queries/queries';
import { Me } from '../Interfaces/interfaces';

// const refreshToken: () => void = async () => {
//   const res = await (await fetch(`${API_URL}/refresh_token`, { method: 'POST', credentials: 'include' })).json();
//   if (res?.ok) localStorage.setItem('token', res?.accessToken);
//   else localStorage.removeItem('token');
// };

const AppRouter: React.FC = () => {
  const { data, error, loading } = useQuery<Me>(ME_QUERY);

  if (error) console.error(error);
  // if (data) if (localStorage.getItem('token') && data.me === undefined) refreshToken();
  if (loading) return <LoaderCheckAuth />;

  return (
    <userContext.Provider value={{ user: data ? data.me : null }}>
      <BrowserRouter>
        {data?.me?.role === 'CLIENT' || data?.me?.role === 'ADMINISTRATOR' || data?.me?.role === 'TECHNICAL_SUPPORT' ? (
          <>
            <AuthUserInfo />
            <Routes>
              <Route path="/equipment/*" element={<Main />}>
                <Route path=":equip" element={<RightSideMain />} />
                {/* <Route path=":equip/statuses" element={<LinesInfoLog />} /> */}
                <Route path=":equip/:lineid" element={<LineInfoLog />} />
              </Route>
              {data.me.role === 'ADMINISTRATOR' ? <Route path="/admin" element={<AdminPanel />} /> : null}
              <Route path="*" element={<Navigate to="/equipment" />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        )}
      </BrowserRouter>
    </userContext.Provider>
  );
};

export default AppRouter;
