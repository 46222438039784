import { LoadingButton } from '@mui/lab';
import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { User } from '../../../../Interfaces/interfaces';
import { DeleteOneUserMutation } from './mutation';

interface DeleteUserModalProps {
  user: User;
  handlerCloseModalWindow(): void;
  handleOpenSnackBar(): void;
  handlerCloseMainWindow(): void;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  user,
  handlerCloseModalWindow,
  handleOpenSnackBar,
  handlerCloseMainWindow,
}) => {
  const [buttonDelete, setButtonDelete] = useState<boolean>(false);
  const [currentRightEnter, setCurrentRightEnter] = useState<boolean>(false);

  const [currentInputValue, setCurrentInputValue] = useState('');
  const handlerChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentInputValue(e.target.value);

    if (user.login.startsWith(e.target.value)) {
      setCurrentRightEnter(true);
      setCurrentInputValue(e.target.value);
    } else setCurrentRightEnter(false);

    if (user.login === e.target.value) setButtonDelete(true);
    else setButtonDelete(false);
  };

  const { deleteOneUserFunc, loadingdeleteOneUser } = DeleteOneUserMutation();
  const clickDeleteButton = async (): Promise<void> => {
    if (user.login === currentInputValue) {
      await deleteOneUserFunc(user.id);
      handleOpenSnackBar();
      handlerCloseModalWindow();
      handlerCloseMainWindow();
    }
  };

  return (
    <Grid>
      <Grid spacing={2} direction="column" container>
        <Grid item>
          <Alert severity="warning">
            Будьте внимательны при удалении пользователя! Прямо сейчас вы удаляете пользователя с логином:{' '}
            <b>{user?.login}</b>
          </Alert>
        </Grid>
        <Grid item>
          <Typography style={{ display: 'flex', marginBottom: 5 }} variant="subtitle1">
            Введите логин пользователя{' '}
            {currentInputValue !== '' && (
              <span
                style={{
                  marginLeft: 5,
                  color: currentRightEnter ? 'green' : 'red',
                  backgroundColor: '#eeefff',
                  padding: '0px 5px',
                  borderRadius: 5,
                  width: 'auto',
                  transition: '0.3s ease',
                }}
              >
                {currentInputValue}
              </span>
            )}
          </Typography>
          <TextField
            style={{ color: 'red' }}
            value={currentInputValue}
            onChange={handlerChangeInput}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 20 }} container justifyContent="space-between">
        <Button onClick={handlerCloseModalWindow}>Отмена</Button>
        <LoadingButton
          onClick={clickDeleteButton}
          loading={loadingdeleteOneUser}
          variant="contained"
          color="warning"
          disabled={!buttonDelete}
        >
          Удалить
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default DeleteUserModal;
