import { Grid, Alert, Typography } from '@mui/material';
import React from 'react';

interface PropsAlertCenter {
  children: React.ReactElement | string;
  severity?: 'success' | 'info' | 'warning' | 'error';
  maxWidth?: 400;
}

export const AlertCenter: React.FC<PropsAlertCenter> = ({ children, severity, maxWidth }) => {
  return (
    <Grid style={{ height: '100vh', maxWidth: maxWidth }} justifyContent="center" item container alignItems="center">
      <Alert severity={severity}>
        <Typography variant="subtitle1" sx={{ lineHeight: 1.6, wordSpacing: 1.2 }}>
          {children}
        </Typography>
      </Alert>
    </Grid>
  );
};
