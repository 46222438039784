import { useLazyQuery } from '@apollo/client';
import { Autocomplete } from '@mui/lab';
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UserGroup } from '../../Interfaces/interfaces';
import { GET_ALL_USER_GROUPS } from './queries';

interface AutocompleteUserGroupsProps {
  currentGroup: UserGroup | undefined;
  handlerChangeCurrentGroups(_e: React.SyntheticEvent<Element, Event>, value: string | null | UserGroup): void;
}

const AutocompleteUserGroups: React.FC<AutocompleteUserGroupsProps> = ({
  currentGroup,
  handlerChangeCurrentGroups,
}) => {
  const [getAllGroups, { data, loading }] = useLazyQuery<{ userGroups: UserGroup[] }>(GET_ALL_USER_GROUPS);

  const [currentGroups, setCurrentGroups] = useState<UserGroup[]>();

  useEffect(() => {
    if (!loading) {
      setCurrentGroups(data?.userGroups);
    }
  }, [data, loading]);

  const handlerFocusInput = async (): Promise<unknown> => await getAllGroups();

  const loadingObject: UserGroup[] = [
    {
      id: '',
      name: '',
      users: [],
      companies: [],
      alertCondition: 'ON_ERROR',
    },
  ];

  return (
    <Autocomplete
      freeSolo
      id="tags-outlined"
      options={currentGroups === undefined ? loadingObject : currentGroups}
      onFocus={handlerFocusInput}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      getOptionLabel={(option: UserGroup): string => option.name}
      value={currentGroup}
      onChange={handlerChangeCurrentGroups}
      renderInput={(params): JSX.Element => (
        <TextField {...params} size="small" fullWidth label="Выберите группу" placeholder="Группы" />
      )}
    />
  );
};

export default AutocompleteUserGroups;
