export const Colors = {
  statusColor: {
    pure: {
      red: '#CA3131',
      yellow: '#E67E22',
      green: '#61C931',
      grey: '#85929E',
      blue: '#3E8AF9',
    },
    pastel: {
      red: 'rgba(121, 31, 31, 0.2)',
      yellow: 'rgba(241, 202, 57, 0.2)',
      green: 'rgba(68, 115, 60, 0.2)',
      grey: '#CCD1D1',
    },
  },
  menu: {
    divider: '1px solid #D4D4D4',
    menuBackgroundColor: '#f9f9f9',
    equipmentFocus: '#E8E8E8',
    heightStatusBarEquipment: 55,
    widthStatusBarEquipment: 5,
    widthStatusBarLine: 4,
    heightStatusBarLine: 60,
    borderRadiusStatusBar: 0,
    activeBackgroundLine: '#D5D8DC',
  },
  rightSide: {
    colorOutLineSerialNumber: '#1D8348',
    colorInfoAbout: '#1F618D',
    emptyInfo: 'rgba(121, 31, 31, 0.6)',
    bottomBorderBetweenComments: '3px solid rgba(100, 100, 100, 0.6)',
    maxWidthScrollGropItem: 500,
  },
  mainBackroundColor: '#fff',
  dropDownMenuBackgroundColor: '#f9f9f9',
};
