import { Company } from './../../../../../Interfaces/interfaces';
import { useMutation, gql } from '@apollo/client';

export const DeleteCompanyMutation = () => {
  const [deleteCompany, { loading }] = useMutation<{ deleteOneCompany: Company }>(gql`
    mutation ($id: String) {
      deleteOneCompany(where: { id: $id }) {
        id
      }
    }
  `);
  const deleteCompanyFunc = (id: string | undefined): Promise<unknown> => deleteCompany({ variables: { id } });

  return { deleteCompanyFunc, loadingdeleteGroup: loading };
};
