import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { CreateErrorMutation } from './mutation';

const Inside: React.FC = () => {
  const { createNewTestErrorFunc, LoadingEraseErrors } = CreateErrorMutation();

  const [serialNumberForTest, setSerialNumberForTest] = useState('');

  const [errorCurrent, setErrorCurrent] = React.useState('');

  const arrayErrors = ['WRONG_PACKAGE_ERROR', 'COM_PORT_CLOSE_ERROR', 'WIM_COUNT_ERROR', 'SENSOR_ERROR', 'AXIS_ERROR'];

  const handleChange = (event: SelectChangeEvent) => {
    setErrorCurrent(event.target.value);
  };

  const onClickAddNewError = async (): Promise<void> => {
    await createNewTestErrorFunc({
      equipmentSerialNumber: serialNumberForTest,
      errorType: errorCurrent,
    });
  };

  return (
    <Grid spacing={2} container>
      <Grid item>
        <TextField
          size="small"
          style={{ width: 300 }}
          value={serialNumberForTest}
          label="Серийный номер устройства"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setSerialNumberForTest(e.target.value)}
        />
      </Grid>
      <Grid item>
        <FormControl size="small" style={{ width: 200 }}>
          <InputLabel id="demo-simple-select-label">Ошибка</InputLabel>
          <Select value={errorCurrent} label="Ошибка" onChange={handleChange}>
            {arrayErrors.map((error, index) => {
              return (
                <MenuItem key={index} value={error}>
                  {error}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <LoadingButton variant="outlined" onClick={onClickAddNewError} loading={LoadingEraseErrors}>
          Создать
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default Inside;
