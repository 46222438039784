import { Tooltip, Zoom } from '@mui/material';
import React from 'react';

interface CustomTooltipProps {
  children: React.ReactElement<any, any>;
  title: string;
  enterDelay?: number;
  placement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, title, enterDelay = 800, placement = 'bottom' }) => {
  return (
    <Tooltip title={title} placement={placement} enterDelay={enterDelay} disableInteractive>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
