import { Container, Grid, Skeleton } from '@mui/material';
import React from 'react';

interface CustomSekeletonProps {
  width?: number;
  height?: number;
}

const CustomSkeleton: React.FC<CustomSekeletonProps> = ({ width = 200, height = 30 }) => {
  return <Skeleton width="100%" style={{ maxWidth: width }} height={height} />;
};

const LoaderOneElement: React.FC = () => {
  return (
    <Grid container>
      <Grid
        container
        item
        justifyContent="space-between"
        style={{ marginBottom: 9, paddingBottom: 9, borderBottom: '1px solid rgba(50,50,50, 0.1)' }}
      >
        <CustomSkeleton />
        <CustomSkeleton />
      </Grid>
    </Grid>
  );
};

const LoaderOneLogick: React.FC = () => {
  return (
    <Grid item style={{ padding: 10, borderRadius: 10, marginBottom: 10 }}>
      <CustomSkeleton height={40} width={400} />
      <LoaderOneElement />
      <LoaderOneElement />
      <LoaderOneElement />
      <LoaderOneElement />
      <LoaderOneElement />
      <LoaderOneElement />
    </Grid>
  );
};

const LoaderRightSide: React.FC = () => {
  return (
    <Container>
      <Grid style={{ marginTop: 10 }} justifySelf="flex-start">
        <Skeleton animation="wave" width={300} height={50} />
      </Grid>
      <LoaderOneLogick />
      <LoaderOneLogick />
      <Grid item spacing={3} container direction="column" alignItems="center">
        <CustomSkeleton height={46} />
        <CustomSkeleton width={340} height={40} />
        <CustomSkeleton height={35} />
      </Grid>
    </Container>
  );
};

export default LoaderRightSide;
