import { LoadingButton } from '@mui/lab';
import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Company } from '../../../../../Interfaces/interfaces';
import { DeleteCompanyMutation } from './mutation';

interface DeleteCompanyModalProps {
  company: Company;
  handlerCloseModalWindow(): void;
  handleOpenSnackBar(): void;
  handlerCloseMainWindow(): void;
}

const DeleteCompanyModal: React.FC<DeleteCompanyModalProps> = ({
  company,
  handlerCloseModalWindow,
  handleOpenSnackBar,
  handlerCloseMainWindow,
}) => {
  const [buttonDelete, setButtonDelete] = useState<boolean>(false);
  const [currentRightEnter, setCurrentRightEnter] = useState<boolean>(false);

  const [currentInputValue, setCurrentInputValue] = useState('');
  const handlerChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentInputValue(e.target.value);
    console.log(e.target.value);

    if (company.name.startsWith(e.target.value)) {
      setCurrentRightEnter(true);
      console.log(company.name);
      console.log(e.target.value);
      setCurrentInputValue(e.target.value);
    } else setCurrentRightEnter(false);

    if (company.name === e.target.value) setButtonDelete(true);
    else setButtonDelete(false);
  };

  const { deleteCompanyFunc, loadingdeleteGroup } = DeleteCompanyMutation();
  const clickDeleteButton = async () => {
    if (company.name === currentInputValue) {
      await deleteCompanyFunc(company.id);
      handleOpenSnackBar();
      handlerCloseModalWindow();
      handlerCloseMainWindow();
    }
  };

  return (
    <Grid>
      <Grid spacing={2} direction="column" container>
        <Grid item>
          <Alert severity="warning">
            Будьте внимательны при удалении группы! Прямо сейчас вы удаляете компанию: <b>{company?.name}</b>
          </Alert>
        </Grid>
        <Grid item>
          <Typography style={{ display: 'flex', marginBottom: 5 }} variant="subtitle1">
            Введите название компании{' '}
            {currentInputValue !== '' && (
              <span
                style={{
                  marginLeft: 5,
                  color: currentRightEnter ? 'green' : 'red',
                  backgroundColor: '#eeefff',
                  padding: '0px 5px',
                  borderRadius: 5,
                  width: 'auto',
                  transition: '0.3s ease',
                }}
              >
                {currentInputValue}
              </span>
            )}
          </Typography>
          <TextField
            style={{ color: 'red' }}
            value={currentInputValue}
            onChange={handlerChangeInput}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 20 }} container justifyContent="space-between">
        <Button onClick={handlerCloseModalWindow}>Отмена</Button>
        <LoadingButton
          onClick={clickDeleteButton}
          loading={loadingdeleteGroup}
          variant="contained"
          color="warning"
          disabled={!buttonDelete}
        >
          Удалить
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default DeleteCompanyModal;
