import { Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import PortalModal from '../Components/Helper/PortalModal';

const InfoAboutCompany: React.FC = () => {
  const [modalInfo, setModalInfo] = useState(false);

  const handlerOpenModalWindow = (): void => setModalInfo(true);
  const handlerCloseModalWindow = (): void => setModalInfo(false);
  return (
    <Grid>
      <Button onClick={handlerOpenModalWindow} color="info">
        Подробнее
      </Button>
      <PortalModal
        open={modalInfo}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Информация о системе мониторинга"
      >
        <Typography> К основным функциям и преимуществам системы мониторинга относятся:</Typography>
        <p>
          - <b>Разграничение доступа</b> пользователей по ролям, группам и организациям
        </p>
        <p>- Отображение списка доступного для мониторинга</p>
        <p>
          - Быстрое отображение наличия ошибок в работе оборудования с помощью <b>цветовых индикаторов</b> в списке
          оборудования
        </p>
        <p>- Отображение даты и времени последнего сеанса обмена данными с оборудованием</p>
        <p>
          {' '}
          - <b>Автоматическое</b> уведомление пользователей о наличии проблем с оборудованием на электронную почту
        </p>
        <p>
          {' '}
          - Автоматическое создание тикетов(задач) технической поддержке <b>ООО «СТАТЕРА»</b>для оперативного
        </p>
        <p>
          {' '}
          - Информация о <i>номера и дате договора поставки, договора технического обслуживания</i>
        </p>
        <p> - Информация об ошибках в работе системы и весовых линий</p>
        <p> - информация о показаниях датчиков</p>
        <p> - Печать отчетов по объекту, системе и измерительным линиям </p>
        <p>
          - Разрабатывается аналитика осциллограмм для <b>оперативного определения нарушений</b> в верхнем строении пути
          просадок шпал итд.
        </p>
        <Grid container justifyContent="flex-end">
          <Button color="warning" onClick={handlerCloseModalWindow}>
            Закрыть
          </Button>
        </Grid>
      </PortalModal>
    </Grid>
  );
};

export default InfoAboutCompany;
