import { gql, useMutation } from '@apollo/client';
import { UserGroup, ALERT_CONDITION } from './../../../../Interfaces/interfaces';

export interface UpdateInputNotification {
  alertCondition: { set: ALERT_CONDITION };
}

export const UdapteNotificationGroup = () => {
  const [udapteNotificationGroup, { loading }] = useMutation<{ updateOneUserGroup: UserGroup }>(gql`
    mutation ($data: UserGroupUpdateInput!, $id: String) {
      updateOneUserGroup(data: $data, where: { id: $id }) {
        id
        alertCondition
      }
    }
  `);
  const udapteNotificationGroupFunc = (data: UpdateInputNotification, id: string | undefined): Promise<unknown> =>
    udapteNotificationGroup({ variables: { data, id } });
  return { udapteNotificationGroupFunc, loadingUdapteNotificationGroup: loading };
};
