import { ClickAwayListener, Box, Grid, SxProps, Paper } from '@mui/material';
import React from 'react';
import { getRusLanguage } from '../../../../Const/getRusLanguage';
import { getStatusColorBackground } from '../../../../Const/getStatusColor';
import { STATUS_CODE } from '../../../../Interfaces/interfaces';

interface MessageSelfProps {
  message: STATUS_CODE;
}

const MessageSelf: React.FC<MessageSelfProps> = ({ message }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = (): void => setOpen(prev => !prev);

  const handleClickAway = (): void => setOpen(false);

  const styles: SxProps = {
    position: 'absolute',
    top: 38,
    right: 0,
    left: 10,
    zIndex: 1,
    border: '2px solid black',
    p: 1,
    bgcolor: 'background.paper',
    display: 'table',
    boxShadow: 5,
  };
  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
      <Box sx={{ position: 'relative', width: 'auto' }}>
        <Grid
          item
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            borderRadius: 5,
            margin: 10,
            padding: 5,
            backgroundColor: getStatusColorBackground(message),
            color: '#fff',
            overflowWrap: 'anywhere',
          }}
        >
          {getRusLanguage(message)}
        </Grid>
        {open ? <Paper sx={styles}>{message}</Paper> : null}
      </Box>
    </ClickAwayListener>
  );
};

export default MessageSelf;
