import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import React from 'react';
import Inside from './Inside';

const TestSendError: React.FC = () => {
  const httpLink2 = new HttpLink({
    uri: 'https://statera-msa-errors-backend.herokuapp.com/graphql',
    credentials: 'include',
  });

  const client2 = new ApolloClient({
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
      },
      query: {
        errorPolicy: 'ignore',
      },
      mutate: {
        errorPolicy: 'ignore',
      },
    },
    cache: new InMemoryCache(),
    link: from([httpLink2]),
    credentials: 'same-origin',
  });

  return (
    <ApolloProvider client={client2}>
      <Inside />
    </ApolloProvider>
  );
};

export default TestSendError;
