import { useMutation, gql } from '@apollo/client';
import { Equipment, Company } from './../../../../Interfaces/interfaces';
import { EquipmentCreateInput } from './interfaces';

export const CreateOneEquipmentMutation = () => {
  const [createOneEquipment, { loading }] = useMutation<{ createOneEquipment: Equipment }>(gql`
    mutation ($data: EquipmentCreateInput!) {
      createOneEquipment(data: $data) {
        serialNumber
      }
    }
  `);
  const createOneEquipmentFunc = (data: EquipmentCreateInput): Promise<unknown> =>
    createOneEquipment({ variables: { data } });

  return { createOneEquipmentFunc, loadingCreateOneEquipment: loading };
};

export interface CompanyUpdateInput {
  equipment: {
    connect: { serialNumber: string };
  };
}

export const UpdateOneCompanyMutation = () => {
  const [updateOneCompany, { loading }] = useMutation<{ updateOneCompany: Company }>(gql`
    mutation ($data: CompanyUpdateInput!, $id: String) {
      updateOneCompany(data: $data, where: { id: $id }) {
        id
      }
    }
  `);
  const updateOneCompanyFunc = (data: CompanyUpdateInput, id: string): Promise<unknown> =>
    updateOneCompany({ variables: { data, id } });

  return { updateOneCompanyFunc, loadingUpdateOneCompany: loading };
};
