import { useLazyQuery } from '@apollo/client';
import { Grid, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Equipment } from '../../../../../Interfaces/interfaces';
import CustomSnackBar from '../../../../Helper/CustomSnackBar';
import SaveButton from '../../ComponentsHelper/SaveButton';
import SelectCompany from '../../SelectCompany/SelectCompany';
import { CompanyUpdateInput, UpdateOneCompanyMutation } from '../mutation';
import AutoCompleteEquipments from './AutoCompleteEquipments';
import { GET_CURRENT_COMPANY_FOR_EQUIPMENT } from './queries';

export const loadingEquipmentsEmpty: Equipment[] = [
  {
    serialNumber: 'loading',
    company: {
      id: 'loading',
      createdAt: new Date(),
      updatedAt: new Date(),
      name: 'loading',
      phone: 'loading',
      email: 'loading',
    },
    lines: [],
  },
];

const ChangeCompanyEquipments: React.FC = () => {
  const [getCurrentCompany, { data: currentCompanyData, loading: loadingCurrentCompanyData }] = useLazyQuery<{
    getOneEquipment: Equipment;
  }>(GET_CURRENT_COMPANY_FOR_EQUIPMENT);

  const [isWrongSnackBar, setIsWrongSnackBar] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const [currentCompany, setCurrentCompany] = useState<string>('');
  const handleChangeCurrentCompany = (event: SelectChangeEvent): void => setCurrentCompany(event.target.value);

  const [currentEquipment, setCurrentEquipment] = useState<string>('');
  const handleChangeEquipment = async (event: SelectChangeEvent): Promise<void> => {
    setCurrentEquipment(event.target.value);
    await getCurrentCompany({ variables: { serialNumber: event.target.value } });
  };

  const { updateOneCompanyFunc, loadingUpdateOneCompany } = UpdateOneCompanyMutation();
  const clickSaveChangeCompanyEquipmentsCompany = async (): Promise<void> => {
    if (
      currentEquipment !== '' &&
      currentEquipment !== undefined &&
      currentCompany !== undefined &&
      currentCompany !== ''
    ) {
      const dataForUpdateCompany: CompanyUpdateInput = { equipment: { connect: { serialNumber: currentEquipment } } };
      await updateOneCompanyFunc(dataForUpdateCompany, currentCompany);
      setIsWrongSnackBar(false);
      setOpenSnackBar(true);
    } else {
      setIsWrongSnackBar(true);
      setOpenSnackBar(true);
    }
  };

  useEffect(() => {
    if (
      !loadingCurrentCompanyData &&
      currentCompanyData &&
      currentCompanyData.getOneEquipment &&
      currentCompanyData.getOneEquipment.company
    ) {
      setCurrentCompany(currentCompanyData?.getOneEquipment.company.id);
    }
  }, [currentCompanyData, loadingCurrentCompanyData]);

  return (
    <Grid style={{ marginTop: 30, padding: 20 }} container>
      <Grid item xs>
        <Grid spacing={2} container direction="column">
          <Grid container direction="column" spacing={2}>
            <Grid style={{ marginLeft: 15 }} item>
              <AutoCompleteEquipments
                currentEquipment={currentEquipment}
                handleChangeEquipment={handleChangeEquipment}
              />
            </Grid>
            <Grid style={{ marginLeft: 15, maxWidth: '100%' }} item>
              <SelectCompany
                loadingCurrentCompanyData={loadingCurrentCompanyData}
                currentCompany={currentCompany}
                handleChangeCurrentCompany={handleChangeCurrentCompany}
              />
            </Grid>
          </Grid>
          <Grid item direction="row-reverse" spacing={1} container justifyContent="space-between">
            <Grid item>
              <SaveButton
                clickSaveChanges={clickSaveChangeCompanyEquipmentsCompany}
                loading={loadingUpdateOneCompany}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isWrongSnackBar ? (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'error'}>
          Извините что-то пошло не так!
        </CustomSnackBar>
      ) : (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'success'}>
          Изменения сохранены
        </CustomSnackBar>
      )}
    </Grid>
  );
};

export default ChangeCompanyEquipments;
