import React from 'react';
import { NavLink } from 'react-router-dom';

interface PropsLinkTo {
  children: React.ReactElement;
  to: string;
}

export const LinkTo: React.FC<PropsLinkTo> = ({ children, to }) => {
  return (
    <NavLink to={to} style={{ textDecoration: 'none', color: '#000' }}>
      {children}
    </NavLink>
  );
};
