import { LoadingButton } from '@mui/lab';
import { Grid, Button, DialogContentText, Alert, TextField, DialogActions, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import PortalModal from '../Helper/PortalModal';
import { EraseErrorMutation } from './graphql/mutatiom';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { ContextExclusionPlugin } from 'webpack';

interface FixButtonLineProps {
  serialNumber: string | undefined;
  lineId: string;
}

const FixButtonLine: React.FC<FixButtonLineProps> = ({ serialNumber, lineId }) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handlerCloseModalWindow = (): void => setOpenDialog(false);
  const handlerOpenModalWindow = (): void => setOpenDialog(true);

  const [errorChangeApiKey, setErrorChangeApiKey] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const [serialNumberCheck, setSerialNumberCheck] = useState('');

  const { EraseErrorsFunc, LoadingEraseErrors } = EraseErrorMutation();
  const handlerClickEraseErrors = async (): Promise<void> => {
    if (serialNumberCheck === serialNumber) {
      await EraseErrorsFunc({ lineId: lineId });
      setErrorChangeApiKey(false);
      setOpenSnackBar(true);
      handlerCloseModalWindow();
    } else if (serialNumberCheck !== serialNumber) {
      setErrorChangeApiKey(true);
      setOpenSnackBar(true);
    } else {
      setErrorChangeApiKey(false);
      setOpenSnackBar(true);
    }
    setSerialNumberCheck('');
  };

  console.log(lineId);

  return (
    <>
      <Grid alignSelf="center" item>
        <Button size="large" color="inherit" variant="contained" onClick={handlerOpenModalWindow}>
          Исправить
        </Button>
      </Grid>
      <PortalModal
        handlerCloseModalWindow={handlerCloseModalWindow}
        open={openDialog}
        dialogTitle="Исправление устройства"
      >
        <DialogContentText style={{ marginBottom: 30 }}>
          <Alert style={{ marginBottom: 20 }} severity="error">
            Если вы уверены в исправлении устройства нажмите <b>Исправить</b>
          </Alert>
        </DialogContentText>
        <TextField
          style={{ marginBottom: 30 }}
          autoFocus
          id="name"
          label="Укажите сериный номер устройства"
          fullWidth
          variant="outlined"
          value={serialNumberCheck}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setSerialNumberCheck(e.target.value)}
        />
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button onClick={handlerCloseModalWindow}>Отмена</Button>
            <LoadingButton
              color="error"
              variant="contained"
              loading={LoadingEraseErrors}
              startIcon={<EngineeringIcon />}
              onClick={handlerClickEraseErrors}
            >
              Исправить
            </LoadingButton>
          </Grid>
        </DialogActions>
      </PortalModal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        {errorChangeApiKey ? (
          <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
            Вы не верно ввели данные!
          </Alert>
        ) : (
          <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
            <Grid container spacing={1}>
              Ошибка исправлена!
            </Grid>
          </Alert>
        )}
      </Snackbar>
    </>
  );
};

export default FixButtonLine;
