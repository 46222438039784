import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Colors } from '../../../Const/Colors';

interface ElementHideProps {
  clickHideElement(): void;
  element: string;
  hideElement: boolean | undefined;
}

const ElementHide: React.FC<ElementHideProps> = ({ clickHideElement, element, hideElement }) => {
  return (
    <Typography
      onClick={clickHideElement}
      style={{ color: Colors.rightSide.colorInfoAbout, cursor: 'pointer' }}
      variant="subtitle1"
    >
      {hideElement ? (
        <Grid style={{ width: 150, height: 20, backgroundColor: 'rgba(20, 20, 20, 0.2)', borderRadius: 4 }} />
      ) : (
        element
      )}
    </Typography>
  );
};

interface DefaultInfoAboutProps {
  element: string | undefined;
  aboutWhatText: string;
  hide?: boolean;
}

export const DefaultInfoAbout: React.FC<DefaultInfoAboutProps> = ({ element, aboutWhatText, hide }) => {
  const [hideElement, setHideElement] = useState(hide);
  const clickHideElement = (): void => setHideElement(!hideElement);
  return (
    <>
      {element !== null && element !== undefined && element !== '' ? (
        <Grid
          container
          justifyContent="space-between"
          style={{ marginBottom: 9, paddingBottom: 9, borderBottom: '1px solid rgba(50,50,50, 0.1)' }}
        >
          <Typography variant="subtitle1">{aboutWhatText}</Typography>

          {hide ? (
            <ElementHide clickHideElement={clickHideElement} element={element} hideElement={hideElement} />
          ) : (
            <Typography style={{ color: Colors.rightSide.colorInfoAbout, cursor: 'pointer' }} variant="subtitle1">
              {element}
            </Typography>
          )}
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="space-between"
          style={{ marginBottom: 9, paddingBottom: 9, borderBottom: '1px solid rgba(50,50,50, 0.1)' }}
        >
          <Typography variant="subtitle1">{aboutWhatText}</Typography>
          <Typography style={{ color: Colors.rightSide.emptyInfo }} variant="subtitle1">
            Информация отсутствует
          </Typography>
        </Grid>
      )}
    </>
  );
};
