import { useMutation, gql } from '@apollo/client';

export interface ErrorCreateInput {
  equipmentSerialNumber: string;
  errorType: string;
}

export const CreateErrorMutation = () => {
  const [createNewTestError, { loading }] = useMutation(gql`
    mutation ($data: CreateNewTestErrorArgs!) {
      createNewTestError(data: $data)
    }
  `);
  const createNewTestErrorFunc = (data: ErrorCreateInput): Promise<unknown> =>
    createNewTestError({ variables: { data } });
  return { createNewTestErrorFunc, LoadingEraseErrors: loading };
};

// export const CalcualteMutation = () => {
//   const [createNewTestError, { loading }] = useMutation(gql`
//     mutation ($serialNumber: String!) {
//       calculateStatus(data: { equipmentSerialNumber: $serialNumber }) {
//         statuses
//       }
//     }
//   `);
//   const createNewTestErrorFunc = (data: ErrorCreateInput): Promise<unknown> =>
//     createNewTestError({ variables: { data } });
//   return { createNewTestErrorFunc, LoadingEraseErrors: loading };
// };
