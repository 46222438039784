import { Button } from '@mui/material';
import React from 'react';

const logOut = (): void => {
  localStorage.removeItem('token');
  window.location.reload();
};
const ButtonLogOut: React.FC = () => {
  return <Button onClick={logOut}>Выйти</Button>;
};

export default ButtonLogOut;
