import { useLazyQuery } from '@apollo/client';
import { Alert, Grid, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UserGroup } from '../../../../Interfaces/interfaces';
import PortalModal from '../../../Helper/PortalModal';
import InfoButton from '../ComponentsHelper/InfoButton';
import { GET_ALL_GROUPTS_FOR_MODAL_WINDOW } from './queries';
import TableAllGroups from './TableAllGroups';

const ShowAllGroup: React.FC = () => {
  const [getAllGroups, { data, loading }] = useLazyQuery<{ userGroups: UserGroup[] }>(GET_ALL_GROUPTS_FOR_MODAL_WINDOW);

  const [currentGroups, setCurrentGroups] = useState<UserGroup[]>();

  useEffect(() => {
    if (!loading) setCurrentGroups(data?.userGroups);
  }, [data?.userGroups, loading]);

  const [openModalWithAllGroups, setOpenModalWithAllGroups] = useState(false);
  const handlerOpenModalWindow = async (): Promise<void> => {
    setOpenModalWithAllGroups(true);
    await getAllGroups();
  };

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };
  const handlerCloseMainWindow = (): void => setOpenModalWithAllGroups(false);

  const handleOpenSnackbar = (): void => setOpenSnackBar(true);

  return (
    <Grid item>
      <InfoButton handlerOpenModalWindow={handlerOpenModalWindow}>Управление группами</InfoButton>
      <PortalModal
        open={openModalWithAllGroups}
        handlerCloseModalWindow={handlerCloseMainWindow}
        dialogTitle="Управление группами"
      >
        {loading ? (
          'loading...'
        ) : (
          <TableAllGroups
            handlerCloseMainWindow={handlerCloseMainWindow}
            handleOpenSnackbar={handleOpenSnackbar}
            currentGroups={currentGroups}
          />
        )}
      </PortalModal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item>Группа удалена!</Grid>
          </Grid>
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default ShowAllGroup;
