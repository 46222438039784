import { Grid } from '@mui/material';
import React from 'react';
import { STATUS_CODE } from '../../../../Interfaces/interfaces';
import MessageSelf from './MessageSelf';

interface StatusesProps {
  statuses: STATUS_CODE[];
}

const Statuses: React.FC<StatusesProps> = ({ statuses }) => {
  return (
    <>
      {statuses !== undefined && (
        <Grid container>
          {statuses.map((statusMessage, index) => {
            return <MessageSelf key={index} message={statusMessage} />;
          })}
        </Grid>
      )}
    </>
  );
};

export default Statuses;
