import { AccountCircle, AlternateEmail } from '@mui/icons-material';
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import { CreateUserInput, ROLE } from '../../../../Interfaces/interfaces';
import ShowAllUsers from '../ShowAllUsers/ShowAllUsers';
import CustomPassword from './CustomPassword';

interface FieldsProps {
  clickAddNewUser(): void;
  userData: CreateUserInput;
  setUserData(obj: CreateUserInput): void;
}

const Fields: React.FC<FieldsProps> = ({ clickAddNewUser, userData, setUserData }) => {
  const roles: ROLE[] = ['ADMINISTRATOR', 'CLIENT', 'TECHNICAL_SUPPORT'];

  const changeUserData = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setUserData({ ...userData, [e.target.id]: e.target.value });

  const [errorsInputs, setErrorsInputs] = useState({
    firstname: false,
    lastname: false,
    login: false,
    email: false,
    password: false,
    role: false,
    repeatPassword: false,
  });

  const handelChangeRole = (e: SelectChangeEvent): void => setUserData({ ...userData, role: e.target.value as ROLE });

  const [reapeatPassword, setRepeatPassword] = useState<string>('');
  const handelChangeRepeatPassword = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setRepeatPassword(e.target.value);

  const clickHandlerAddButton = (): void => {
    const passwordValidate =
      reapeatPassword === userData.password && userData.password !== '' && reapeatPassword !== '';

    if (passwordValidate) setErrorsInputs({ ...errorsInputs, password: false, repeatPassword: false });
    else setErrorsInputs({ ...errorsInputs, password: true, repeatPassword: true });

    setErrorsInputs(errors => {
      if (userData.email === '') errors.email = true;
      else errors.email = false;

      if (userData.firstname === '') errors.firstname = true;
      else errors.firstname = false;

      if (userData.lastname === '') errors.lastname = true;
      else errors.lastname = false;

      if (userData.login === '') errors.login = true;
      else errors.login = false;

      return errors;
    });

    console.log(userData);

    const notFieldsEmpty =
      userData.firstname && userData.lastname && userData.email && userData.login && userData.role && userData.password;

    if (passwordValidate && notFieldsEmpty) {
      setRepeatPassword('');
      clickAddNewUser();
    }
  };

  type SizeOfFieldType = 'small' | 'medium';
  const sizeField: SizeOfFieldType = 'small';

  return (
    <Grid container spacing={2} item>
      <Typography style={{ marginBottom: 30 }} variant="h5">
        Добавление нового пользователя
      </Typography>
      <Grid spacing={2} container>
        <Grid spacing={2} justifyContent="space-between" item container>
          <Grid item xs>
            <TextField
              autoComplete="off"
              error={errorsInputs.lastname}
              id="lastname"
              onChange={changeUserData}
              value={userData.lastname}
              fullWidth
              size={sizeField}
              label="Фамилия"
            />
          </Grid>
          <Grid item xs>
            <TextField
              autoComplete="off"
              error={errorsInputs.firstname}
              id="firstname"
              onChange={changeUserData}
              value={userData.firstname}
              fullWidth
              label="Имя"
              size={sizeField}
            />
          </Grid>
          <Grid item xs>
            <TextField
              autoComplete="off"
              id="middleName"
              onChange={changeUserData}
              value={userData.middleName}
              size={sizeField}
              fullWidth
              label="Отчество (не обезательно)"
            />
          </Grid>
        </Grid>
        <Grid spacing={2} item container>
          <Grid item xs>
            <TextField
              autoComplete="off"
              size={sizeField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              error={errorsInputs.login}
              id="login"
              label="Логин"
              fullWidth
              onChange={changeUserData}
              value={userData.login}
            />
          </Grid>
          <Grid item xs>
            <TextField
              autoComplete="off"
              size={sizeField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmail />
                  </InputAdornment>
                ),
              }}
              error={errorsInputs.email}
              id="email"
              label="Email"
              fullWidth
              onChange={changeUserData}
              value={userData.email}
            />
          </Grid>
          <Grid spacing={2} item container justifyContent="space-between">
            <Grid item xs>
              <CustomPassword
                size={sizeField}
                errorPassword={errorsInputs.password}
                onChangeInput={changeUserData}
                value={userData.password}
                title="Пароль"
                id="password"
              />
            </Grid>
            <Grid item xs>
              <CustomPassword
                size={sizeField}
                errorPassword={errorsInputs.password}
                onChangeInput={handelChangeRepeatPassword}
                value={reapeatPassword}
                title="Повторите пароль"
                id="repeat password"
              />
            </Grid>
          </Grid>
          <Grid item xs>
            <FormControl size={sizeField} fullWidth>
              <InputLabel id="demo-simple-select-label">Выберите роль</InputLabel>
              <Select size={sizeField} onChange={handelChangeRole} label="Выберите роль" value={userData.role}>
                {roles.map((role, index) => {
                  return (
                    <MenuItem key={index} value={role}>
                      {role}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 20 }} justifyContent="space-between" container>
        <Grid item>
          <ShowAllUsers />
        </Grid>
        <Grid item>
          <Button onClick={clickHandlerAddButton} variant="contained">
            Добавить
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Fields;
