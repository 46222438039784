import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

interface PortalModalProps {
  children: React.ReactNode | React.ReactChild;
  dialogTitle: string;
  acceptButtonText?: string;
  open: boolean;
  handlerCloseModalWindow(): void;
}

const PortalModal: React.FC<PortalModalProps> = ({ children, dialogTitle, open, handlerCloseModalWindow }) => {
  return (
    <div>
      <Dialog open={open} onClose={handlerCloseModalWindow}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
};

export default PortalModal;
