import { Alert, Container, Grid, Paper, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { SingUpMutaion } from '../../../graphql/mutations/mutations';
import { CreateUserInput } from '../../../Interfaces/interfaces';
import CustomizedDialogs from './Dialog';
import Fields from './Fields/Fields';

const AddUser: React.FC = () => {
  const [userData, setUserData] = useState<CreateUserInput>({
    firstname: '',
    lastname: '',
    login: '',
    email: '',
    password: '',
    role: 'CLIENT',
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorAddUser, setErrorAddUser] = useState('');

  const handleCloseDialog = (): void => setOpenDialog(false);

  const { signUserUpFunc, SignUpLoading } = SingUpMutaion();

  const clickAddNewUserSure = async (): Promise<void> => {
    const notEmptyFields =
      userData.firstname && userData.lastname && userData.email && userData.login && userData.password && userData.role;

    if (notEmptyFields) {
      const response = await signUserUpFunc(userData);

      if (response.data?.signUp !== null) {
        setErrorAddUser('');
        setOpenDialog(false);
        setOpenSnackBar(true);
        userData.firstname = '';
        userData.lastname = '';
        userData.login = '';
        userData.role = 'CLIENT';
        userData.password = '';
        userData.email = '';
        userData.middleName = '';
      } else {
        setErrorAddUser('Данные введены не корректно!');
        setOpenDialog(false);
        setOpenSnackBar(true);
      }
    }
  };

  const clickAddNewUser = (): void => setOpenDialog(true);

  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  return (
    <Container style={{ maxWidth: 950 }}>
      <Paper elevation={3} style={{ padding: 50 }}>
        <Fields userData={userData} setUserData={setUserData} clickAddNewUser={clickAddNewUser} />
        <CustomizedDialogs
          userData={userData}
          open={openDialog}
          clickAddNewUserSure={clickAddNewUserSure}
          handleCloseDialog={handleCloseDialog}
          loading={SignUpLoading}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          {errorAddUser !== '' ? (
            <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
              Данные введены не корректно, или такой пользователь уже есть!
            </Alert>
          ) : (
            <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid item>Новый пользователь: </Grid>
                <Grid item style={{ color: 'red' }}>
                  {userData.login}
                </Grid>
                <Grid item> добавлен!</Grid>
              </Grid>
            </Alert>
          )}
        </Snackbar>
      </Paper>
    </Container>
  );
};

export default AddUser;
