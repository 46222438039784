import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import CustomSnackBar from '../../../Helper/CustomSnackBar';
import PortalModal from '../../../Helper/PortalModal';
import Field from '../AddNewEquipment/Field';
import AddButton from '../ComponentsHelper/AddButton';
import { CompanyCreateInput, CreateOneCompanyMutation } from './mutation';

interface FieldsInterface {
  value: string | Date | null;
  label: string;
  id: string;
  error: boolean;
  isDate?: boolean;
}

const FieldsConst: FieldsInterface[] = [
  {
    label: 'Введите название компании',
    id: 'name',
    value: '',
    error: false,
  },
  {
    label: 'Дата создания',
    id: 'createdAt',
    value: null,
    error: false,
    isDate: true,
  },
  {
    label: 'Номер телефона',
    id: 'phone',
    value: '',
    error: false,
  },
  {
    label: 'Элетронная почта',
    id: 'email',
    value: '',
    error: false,
  },
];

const NewCompany: React.FC = () => {
  const [openModalWithAllGroups, setOpenModalWithAllGroups] = useState(false);

  const [equipmentsFields, setEquipmentsFields] = useState<FieldsInterface[]>(FieldsConst);
  const onChangeHandlerFieldsNameGroup = (value: string, id: string): void => {
    setEquipmentsFields(
      equipmentsFields.map(field => {
        if (field.id === id) field.value = value;
        return field;
      }),
    );
  };

  const [isWrongSnackBar, setIsWrongSnackBar] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const handlerOpenModalWindow = (): void => setOpenModalWithAllGroups(true);
  const handlerCloseModalWindow = (): void => setOpenModalWithAllGroups(false);

  const { createOneCompanyFunc, loadingCreateOneCompany } = CreateOneCompanyMutation();
  const clickCreateOneNewCompany = async (): Promise<void> => {
    let isCanUpload = true;
    setEquipmentsFields(equipmentsFields =>
      equipmentsFields.map(field => {
        console.log(field.value);
        if (field.value === '' || field.value === null) {
          field.error = true;
          isCanUpload = false;
        } else field.error = false;
        return field;
      }),
    );

    if (isCanUpload) {
      let companyData: CompanyCreateInput = {};

      equipmentsFields.map(field => {
        companyData = {
          ...companyData,
          [field.id]: field.value,
        };
      });
      await createOneCompanyFunc(companyData);

      handlerCloseModalWindow();
      setIsWrongSnackBar(false);
      setOpenSnackBar(true);
    } else {
      setIsWrongSnackBar(true);
      setOpenSnackBar(true);
    }
  };

  return (
    <Grid item>
      <AddButton handlerOpenModalWindow={handlerOpenModalWindow}>Добавить новую компанию</AddButton>
      <PortalModal
        open={openModalWithAllGroups}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Добавление новой компании"
      >
        <Grid style={{ margin: 15, width: 500 }}>
          {FieldsConst.map(field => {
            return (
              <Field
                key={field.id}
                label={field.label}
                id={field.id}
                value={field.value}
                error={field.error}
                isDate={field.isDate}
                onChange={onChangeHandlerFieldsNameGroup}
              />
            );
          })}
          <Grid style={{ marginTop: 10 }} container justifyContent="space-between">
            <Grid item>
              <Button onClick={handlerCloseModalWindow}>Отменить</Button>
            </Grid>
            <Grid item>
              <LoadingButton onClick={clickCreateOneNewCompany} loading={loadingCreateOneCompany} variant="outlined">
                Добавить
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </PortalModal>
      {isWrongSnackBar ? (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'error'}>
          Извините что-то пошло не так!
        </CustomSnackBar>
      ) : (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'success'}>
          Новая компания добавлена!
        </CustomSnackBar>
      )}
    </Grid>
  );
};

export default NewCompany;
