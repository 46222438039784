import { useQuery } from '@apollo/client';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button, ButtonGroup, Grid, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../Assets/img/logo.png';
import { Colors } from '../../Const/Colors';
import { userContext } from '../../Context/context';
import { GET_ALL_EQUIPMENTS, GET_ALL_MY_EQUIPMENTS } from '../../graphql/queries/queries';
import { AllEquipments, Equipment } from '../../Interfaces/interfaces';
import CustomTooltip from '../Helper/CustomTooltip';
import EquipmentItem from './EquipmentItem';
import SkeletonDevicesMenu from './SkeletonDevicesMenu';

const DevicesMenu: React.FC = () => {
  const { user } = useContext(userContext);
  const { data, loading } = useQuery<AllEquipments>(
    user?.role === 'ADMINISTRATOR' || user?.role === 'TECHNICAL_SUPPORT' ? GET_ALL_EQUIPMENTS : GET_ALL_MY_EQUIPMENTS,
  );

  const [equipments, setEquipments] = useState<Equipment[] | undefined>();

  const [forSearchEquipment, setForSearchEquipment] = useState<Equipment[] | undefined>();

  const [searchValue, setSearchValue] = useState<string>('');
  const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
    const searched = forSearchEquipment?.filter(
      search =>
        search.serialNumber.includes(e.target.value) ||
        search.company.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setEquipments(searched);
  };

  enum SortFilter {
    name,
    serialNumber,
    nothing,
  }

  const [sortFilter, setSortFilter] = useState<SortFilter>(SortFilter.nothing);
  const [isAlreadySortSerialNumber, setIsAlreadySortSerialNumber] = useState(false);
  const [isALreadySortByName, setIsALreadySortByName] = useState(false);

  const sortBySerialNumberFirstTime = (equipmentsForSort: Equipment[]): Equipment[] => {
    if (equipmentsForSort !== undefined) {
      const arrayForSort = [...equipmentsForSort];

      arrayForSort.sort(function (a, b) {
        const nameA = a.serialNumber.toLowerCase(),
          nameB = b.serialNumber.toLowerCase();

        return nameA > nameB ? 1 : -1;
      });

      setIsAlreadySortSerialNumber(true);
      setSortFilter(SortFilter.serialNumber);
      return arrayForSort;
    }
    return [];
  };

  const sortBySerialNumber = (): void => {
    if (equipments !== undefined) {
      const arrayForSort = [...equipments];

      if (!isAlreadySortSerialNumber) {
        arrayForSort.sort(function (a, b) {
          const nameA = a.serialNumber.toLowerCase(),
            nameB = b.serialNumber.toLowerCase();

          return nameA > nameB ? 1 : -1;
        });
      } else arrayForSort.reverse();

      setEquipments(arrayForSort);
      setIsAlreadySortSerialNumber(!isAlreadySortSerialNumber);
      setSortFilter(SortFilter.serialNumber);
    }
  };

  const sortByName = (): void => {
    if (equipments !== undefined) {
      const arrayForSort = [...equipments];

      if (!isALreadySortByName) {
        arrayForSort.sort(function (a, b) {
          const nameA = a.company.name.toLowerCase(),
            nameB = b.company.name.toLowerCase();

          return nameA > nameB ? 1 : -1;
        });
      } else arrayForSort.reverse();
      setIsALreadySortByName(!isALreadySortByName);
      setEquipments(arrayForSort);
      setSortFilter(SortFilter.name);
    }
  };

  useEffect(() => {
    if (data !== undefined) {
      setEquipments(
        user?.role === 'ADMINISTRATOR' || user?.role === 'TECHNICAL_SUPPORT'
          ? sortBySerialNumberFirstTime(data?.getAllEquipment!)
          : sortBySerialNumberFirstTime(data.getAllMyEquipment!),
      );
      setForSearchEquipment(
        user?.role === 'ADMINISTRATOR' || user?.role === 'TECHNICAL_SUPPORT'
          ? sortBySerialNumberFirstTime(data?.getAllEquipment!)
          : sortBySerialNumberFirstTime(data.getAllMyEquipment!),
      );
    }
  }, [data, user]);

  const haveEquipments = equipments === undefined ? false : true;
  return (
    <Grid
      item
      justifyContent="center"
      style={{
        background: Colors.menu.menuBackgroundColor,
      }}
    >
      <Grid container justifyContent="center" style={{ padding: 0 }}>
        <Grid style={{ marginTop: 8 }} container direction="column" item alignItems="center" justifyContent="center">
          <Grid style={{ marginBottom: 8 }} item xs>
            <NavLink style={{ textDecoration: 'none' }} to="/">
              <img src={logo} style={{ width: 200 }} />
            </NavLink>
          </Grid>
          <Grid item xs>
            <TextField
              style={{ width: 300 }}
              fullWidth
              value={searchValue}
              onChange={onChangeSearchValue}
              size="small"
              placeholder="Поиск"
            />
          </Grid>
          <Grid style={{ marginTop: 10 }}>
            <ButtonGroup color="info" variant="outlined">
              <CustomTooltip title="Соритровать по серийному номеру">
                <Button
                  startIcon={!isAlreadySortSerialNumber ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                  size="small"
                  variant={sortFilter === SortFilter.serialNumber ? 'contained' : 'outlined'}
                  onClick={sortBySerialNumber}
                >
                  Серийный номер
                </Button>
              </CustomTooltip>
              <CustomTooltip title="Сортировать по алфавиту">
                <Button
                  startIcon={!isALreadySortByName ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                  size="small"
                  variant={sortFilter === SortFilter.name ? 'contained' : 'outlined'}
                  onClick={sortByName}
                >
                  Название
                </Button>
              </CustomTooltip>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          style={{
            borderTop: '1px solid rgba(0, 0, 0, 0.6)',
            paddingTop: 6,
            marginLeft: 10,
            marginTop: 10,
          }}
        >
          {loading ? (
            <Grid>
              <SkeletonDevicesMenu />
              <SkeletonDevicesMenu />
              <SkeletonDevicesMenu />
              <SkeletonDevicesMenu />
              <SkeletonDevicesMenu />
              <SkeletonDevicesMenu />
              <SkeletonDevicesMenu />
              <SkeletonDevicesMenu />
            </Grid>
          ) : haveEquipments ? (
            equipments?.map(equipment => {
              return <EquipmentItem equipment={equipment} key={equipment.serialNumber} />;
            })
          ) : (
            <Grid>Доступных девайсов нет</Grid>
          )}
        </Grid>
        <Grid />
      </Grid>
    </Grid>
  );
};
export default DevicesMenu;
