import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { getFullTime } from '../../Const/getTime';
import { LineRecord } from '../../Interfaces/interfaces';
import TableCellRecord from './TableCellRecord/TableCellRecord';

interface TableCurrentRecordsProps {
  currentRecords: LineRecord;
}

const TableCurrentRecords: React.FC<TableCurrentRecordsProps> = ({ currentRecords }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Дата</TableCell>
            <TableCellRecord count={1} />
            <TableCellRecord count={2} />
            <TableCellRecord count={3} />
            <TableCellRecord count={4} />
            <TableCellRecord count={5} />
            <TableCellRecord count={6} />
            <TableCellRecord count={7} />
            <TableCellRecord count={8} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>{getFullTime(currentRecords.createdAt)}</TableCell>
            <TableCellRecord value={currentRecords.value1} SKO={currentRecords.SKO1} />
            <TableCellRecord value={currentRecords.value2} SKO={currentRecords.SKO2} />
            <TableCellRecord value={currentRecords.value3} SKO={currentRecords.SKO3} />
            <TableCellRecord value={currentRecords.value4} SKO={currentRecords.SKO4} />
            <TableCellRecord value={currentRecords.value5} SKO={currentRecords.SKO5} />
            <TableCellRecord value={currentRecords.value6} SKO={currentRecords.SKO6} />
            <TableCellRecord value={currentRecords.value7} SKO={currentRecords.SKO7} />
            <TableCellRecord value={currentRecords.value8} SKO={currentRecords.SKO8} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCurrentRecords;
