import { Button } from '@mui/material';
import React from 'react';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MainButtonSetting from './MainButtonSetting';

interface InfoButtonProps {
  handlerOpenModalWindow(): void;
  children: React.ReactChild | React.ReactNode;
}

const InfoButton: React.FC<InfoButtonProps> = ({ children, handlerOpenModalWindow }) => {
  return (
    <MainButtonSetting
      handlerOpenModalWindow={handlerOpenModalWindow}
      Icon={<CorporateFareIcon />}
      variant="outlined"
      color="info"
    >
      {children}
    </MainButtonSetting>
  );
};

export default InfoButton;
