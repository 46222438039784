import { CreateUserInput, ErrorsInput } from './../../Interfaces/interfaces';
import { gql, useMutation } from '@apollo/client';
import { SignIn, SignUp } from '../../Interfaces/interfaces';

export const SingInMutaion = () => {
  const [signIn, { loading }] = useMutation<{ signIn: SignIn }>(gql`
    mutation AUTH_USER($data: SignInInput!) {
      signIn(data: $data) {
        token
      }
    }
  `);
  const signInUserFunc = (data: { login: string; password: string }) => signIn({ variables: { data } });
  return { signInUserFunc, loading };
};

export const SingUpMutaion = () => {
  const [signUp, { loading }] = useMutation<{ signUp: SignUp | ErrorsInput }>(gql`
    mutation SignUp($data: CreateUserInput!) {
      signUp(data: $data) {
        token
      }
    }
  `);
  const signUserUpFunc = (data: CreateUserInput) => signUp({ variables: { data } });
  return { signUserUpFunc, SignUpLoading: loading };
};
