import React, { useContext } from 'react';
import { userContext } from '../Context/context';

interface HideFromClientProps {
  children: React.ReactChild | React.ReactNode;
}

const HideFromClient: React.FC<HideFromClientProps> = ({ children }) => {
  const context = useContext(userContext);

  return <>{context.user?.role === 'CLIENT' ? <></> : children}</>;
};

export default HideFromClient;
