import { Grid } from '@mui/material';
import React from 'react';
import { Line } from '../../Interfaces/interfaces';
import { Colors } from '../../Const/Colors';
import CurrentStatuses from './CurrentStatuses';

interface PropsLineItem {
  line: Line;
}

const LineItem: React.FC<PropsLineItem> = ({ line }) => {
  return (
    <Grid
      style={{
        padding: '7px 5px',
        marginLeft: 0,
        cursor: 'pointer',
        // backgroundColor: 'green',
        borderBottom: Colors.menu.divider,
      }}
      direction="row"
      container
      item
    >
      {line?.currentStatus?.status?.map((status, index) => {
        return <CurrentStatuses status={status} key={index} />;
      })}
    </Grid>
  );
};

export default LineItem;
