import { gql, useMutation } from '@apollo/client';
import { UserGroup } from '../../../Interfaces/interfaces';

export interface UserGroupUpdateInput {
  users: UserUpdateManyWithoutGroupsInput;
  companies: UserUpdateManyWithoutGroupsInput;
}

export interface UserUpdateManyWithoutGroupsInput {
  set: UserWhereUniqueInput[];
}

export interface UserWhereUniqueInput {
  id: string;
}

export const UpdateUserGroupMutation = () => {
  const [updateUserGroup, { loading }] = useMutation<{ updateOneUserGroup: UserGroup }>(gql`
    mutation ($data: UserGroupUpdateInput!, $companyId: String) {
      updateOneUserGroup(data: $data, where: { id: $companyId }) {
        id
      }
    }
  `);
  const updateUserGroupFunc = (data: UserGroupUpdateInput, companyId: string | undefined): Promise<unknown> =>
    updateUserGroup({ variables: { data, companyId } });

  return { updateUserGroupFunc, loadingUpdateGroup: loading };
};
