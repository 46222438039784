import { LoadingButton } from '@mui/lab';
import { Alert, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import background from '../Assets/img/background.jpg';
import logo from '../Assets/img/logo.png';
import CustomPassword from '../Components/Admin/AddUser/Fields/CustomPassword';
import { SingInMutaion } from '../graphql/mutations/mutations';
import InfoAboutCompany from './InfoAboutCompany';

interface LogInInput {
  login: string;
  password: string;
}

const Login: React.FC = () => {
  const [loginValue, setLogin] = useState<string>('');
  const [passwordValue, setPassword] = useState<string>('');
  const [errorTextEnter, setErrorTextEnter] = useState<string>('');

  const { signInUserFunc, loading } = SingInMutaion();

  const handlerLoginButton = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (loginValue != '' && passwordValue != '') {
      const response = await signInUserFunc({ login: loginValue, password: passwordValue });
      if (response.data && response.data?.signIn !== null) {
        localStorage.setItem('token', response.data.signIn.token);
        window.location.reload();
      } else setErrorTextEnter('Логин или пароль введены не верно');
    }
  };

  return (
    <Grid
      style={{
        // backgroundImage: `url(${background})`,
        backgroundColor: '#fff',
        height: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      container
      alignItems="center"
      direction="column"
    >
      <Grid style={{ marginTop: 30 }} item>
        <img width={250} src={logo} />
      </Grid>
      <Grid style={{ marginBottom: 30 }} item>
        <Typography variant="h5" style={{ textTransform: 'uppercase' }}>
          Система мониторинга оборудования ООО &quot;СТАТЕРА&quot;
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          backgroundColor: 'rgba(250, 250, 250, 1)',
          boxShadow: '0px 5px 19px -3px rgba(38, 60, 77, 0.6)',
          borderRadius: '3px',
          maxWidth: 550,
          padding: 30,
          height: 350,
        }}
        justifyContent="center"
        alignItems="center"
        container
      >
        <Grid item justifySelf="center">
          <Typography
            style={{ color: '#2e2e2e', paddingTop: 7, marginBottom: 30, fontSize: 'clamp(32px, 4vw, 22px)' }}
            variant="h3"
          >
            Авторизация
          </Typography>
        </Grid>
        <Grid item container direction="column">
          <form onSubmit={handlerLoginButton}>
            <Grid item container direction="column">
              <Grid>
                <TextField
                  required
                  error={errorTextEnter === '' ? false : true}
                  style={{
                    marginBottom: 30,
                    color: '#3ce209',
                  }}
                  size="small"
                  disabled={loading}
                  fullWidth={true}
                  label="Введите логин"
                  value={loginValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setLogin(e.target.value)}
                />
              </Grid>
              <CustomPassword
                errorPassword={errorTextEnter === '' ? false : true}
                disabled={loading}
                onChangeInput={(e: React.ChangeEvent<HTMLInputElement>): void => setPassword(e.target.value)}
                value={passwordValue}
                title="Пароль"
                id="password"
                size="small"
              />
            </Grid>
            <Grid style={{ marginTop: 15 }}>
              {errorTextEnter !== '' ? <Alert severity="error">{errorTextEnter}</Alert> : null}
            </Grid>
            <Grid style={{ marginTop: 15 }} container justifyContent="flex-end" item>
              <LoadingButton loading={loading} variant="contained" type="submit">
                Войти
              </LoadingButton>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 50 }}>
        <InfoAboutCompany />
      </Grid>
    </Grid>
  );
};

export default Login;
