import { Grid, Typography } from '@mui/material';
import React from 'react';
import PortalAdminGroupSettings from '../../../Helper/PortalAdminGroupSettings';
import ChangeCompanyEquipments from './ChangeCompanyInEquipment/ChangeCompanyEquipments';
import NewEquip from './NewEquip';
import ShowAllEquipments from './ShowAllEquipments/ShowAllEquipments';

const AddNewEquipment: React.FC = () => {
  return (
    <PortalAdminGroupSettings text="Добавить оборудование в компанию">
      <Grid container>
        <Grid xs={7} item>
          <ChangeCompanyEquipments />
        </Grid>
        <Grid container direction="column" spacing={2} xs={5} item>
          <PortalAdminGroupSettings elevation={0} text="Работа с устройством">
            <Grid item>
              <NewEquip />
            </Grid>
            <Grid item>
              <ShowAllEquipments />
            </Grid>
          </PortalAdminGroupSettings>
        </Grid>
      </Grid>
    </PortalAdminGroupSettings>
  );
};

export default AddNewEquipment;
