import { STATUS_CODE } from './../Interfaces/interfaces';
const statuses = [
  'ERROR_ERASED',
  'OK',
  'WIM_STARTUP_CRITICAL',
  'WIM_STARTUP_WARNING',
  'WRONG_PACKAGE_STRUCTURE_CRITICAL',
  'WRONG_PACKAGE_STRUCTURE_WARNING',
  'COM_PORT_CLOSE_CRITICAL',
  'COM_PORT_CLOSE_WARNING',
  'NON_RECOGNISED_AXES_CRITICAL',
  'NON_RECOGNISED_AXES_WARNING',
  'SENSOR_CRITICAL',
  'SENSOR_WARNING',
  'MICROTIC_WARNING',
  'MICROTIC_CRITICAL',
  'PING_CRITICAL',
  'PING_WARNING',
];

enum statusesRus {
  ERROR_ERASED = 'ОШИБКА ОЧИЩЕНА',
  OK = 'OK',
  WIM_START_COUNT_CRITICAL = 'Количество запусков/закрытий WIM критично',
  WIM_START_COUNT_WARNING = 'Количество запусков/закрытий WIM, предупреждение',
  WRONG_PACKAGE_STRUCTURE_CRITICAL = 'Неверная структура пакетов, критично',
  WRONG_PACKAGE_STRUCTURE_WARNING = 'Неверная структура пакетов, предупреждение',
  CRITICAL_COM_PORT_TEMPERATURE_ERRORS = 'Критичная температура Com порта',
  WARNING_COM_PORT_TEMPERATURE_ERRORS = 'Выше среднего температура Com порта',
  COM_PORT_CLOSE_ERRORS_CRITICAL = 'Критичное количество закрытий Com порта',
  COM_PORT_CLOSE_ERRORS_WARNING = 'Выше среднего количество закрытий Com порта',
  NON_RECOGNISED_AXES_CRITICAL = 'Критическое количество нераспознаных осей',
  NON_RECOGNISED_AXES_WARNING = 'Выше среднего количество нераспознаных осей',
  SENSOR_CRITICAL = 'Значение одного из датчиков критично',
  SENSOR_WARNING = 'Значение одного из датчиков, предупреждение',
  MIKROTIK_CRITICAL = 'Скрипт из Микротика выдал критично',
  PING_CRITICAL = 'Критичный уровень пропажи пинга',
  PING_WARNING = 'Выше среднего уровень пропажи пинга',
}

export const getRusLanguage = (message: STATUS_CODE): string => statusesRus[message];
