import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query {
    users {
      id
      firstname
      lastname
      middleName
      login
      role
    }
  }
`;
