import React from 'react';
import PortalAdminGroupSettings from '../../../Helper/PortalAdminGroupSettings';
import DeleteCompany from './DeleteCompany/DeleteCompany';
import NewCompany from './NewCompany';

const CompanyGroup = () => {
  return (
    <PortalAdminGroupSettings text="Работа с компаниями">
      <NewCompany />
      <DeleteCompany />
    </PortalAdminGroupSettings>
  );
};

export default CompanyGroup;
