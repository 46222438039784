import { LoadingButton } from '@mui/lab';
import { Button, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { ALERT_CONDITION } from '../../../../Interfaces/interfaces';
import CustomSnackBar from '../../../Helper/CustomSnackBar';
import PortalModal from '../../../Helper/PortalModal';
import AddButton from '../ComponentsHelper/AddButton';
import { arrayCondition } from '../ShowAllGroup/OneGroupTable';
import { CreateOneUserGroup, CreateOneUserGroupMutation } from './mutation';

const AddNewGroupButton: React.FC = () => {
  const [openModalWithAllGroups, setOpenModalWithAllGroups] = useState(false);

  const [errorInputName, setErrorInputName] = useState(false);
  const [nameGroupField, setNameGroupField] = useState<string>('');
  const onChangeHandlerFieldsNameGroup = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setNameGroupField(e.target.value);

  const [isWrongSnackBar, setIsWrongSnackBar] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const [alertCondition, setAlertCondition] = useState<ALERT_CONDITION>('ALERT_OFF');
  const handleChange = (event: SelectChangeEvent): void => setAlertCondition(event.target.value as ALERT_CONDITION);

  const handlerOpenModalWindow = (): void => {
    setOpenModalWithAllGroups(true);
    setNameGroupField('');
  };

  const handlerCloseModalWindow = (): void => {
    setOpenModalWithAllGroups(false);
  };

  const { createOneUserGroupFunc, loadingCreateUserGroup } = CreateOneUserGroupMutation();
  const clickAddNewGroup = async (): Promise<void> => {
    if (nameGroupField !== '') {
      setErrorInputName(false);
      const createNewData: CreateOneUserGroup = {
        name: nameGroupField,
        alertCondition: alertCondition,
      };
      await createOneUserGroupFunc(createNewData);
      setOpenSnackBar(true);
      setIsWrongSnackBar(false);
      handlerCloseModalWindow();
    } else if (nameGroupField === '') {
      setErrorInputName(true);
      setOpenSnackBar(true);
      setIsWrongSnackBar(true);
    }
  };

  return (
    <Grid item>
      <AddButton handlerOpenModalWindow={handlerOpenModalWindow}>Добавить новую группу</AddButton>
      <PortalModal
        open={openModalWithAllGroups}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Добавление новой группы"
      >
        <Grid style={{ margin: 15, width: 500 }}>
          <TextField
            style={{ marginBottom: 15 }}
            size="small"
            label="Название новой группы"
            placeholder="Название"
            fullWidth
            value={nameGroupField}
            error={errorInputName}
            onChange={onChangeHandlerFieldsNameGroup}
          />
          <InputLabel id="demo-simple-select-label">Статус оповещений</InputLabel>
          <Select
            fullWidth
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={alertCondition}
            onChange={handleChange}
          >
            {arrayCondition.map((cond, index) => {
              return (
                <MenuItem key={index} value={cond.value}>
                  {cond.title}
                </MenuItem>
              );
            })}
          </Select>
          <Grid style={{ marginTop: 10 }} container justifyContent="space-between">
            <Grid item>
              <Button onClick={handlerCloseModalWindow}>Отменить</Button>
            </Grid>
            <Grid item>
              <LoadingButton onClick={clickAddNewGroup} loading={loadingCreateUserGroup} variant="outlined">
                Добавить
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </PortalModal>
      {isWrongSnackBar ? (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'error'}>
          Извините что-то пошло не так!
        </CustomSnackBar>
      ) : (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'success'}>
          Новая группа добавлена!
        </CustomSnackBar>
      )}
    </Grid>
  );
};

export default AddNewGroupButton;
