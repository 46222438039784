import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query {
    users {
      id
      login
      firstname
      lastname
      role
    }
  }
`;

export const GET_CURRENT_USERS_FOR_GROUPS = gql`
  query ($id: String) {
    userGroup(where: { id: $id }) {
      users {
        id
        firstname
        lastname
        email
        login
        role
      }
    }
  }
`;

export const GET_ALL_COMPANY_FOR_AUTOCOMPLETE = gql`
  query {
    companies {
      id
      name
    }
  }
`;

export const GET_CURRENT_COMPANY_FOR_AUTOCOMPLETE = gql`
  query ($id: String) {
    userGroup(where: { id: $id }) {
      companies {
        id
        name
      }
    }
  }
`;
