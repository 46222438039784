import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, CircularProgress, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Company } from '../../../../Interfaces/interfaces';
import CustomSnackBar from '../../../Helper/CustomSnackBar';
import PortalModal from '../../../Helper/PortalModal';
import AddButton from '../ComponentsHelper/AddButton';
import Field from './Field';
import { EquipmentCreateInput } from './interfaces';
import { CreateOneEquipmentMutation } from './mutation';
import { GET_ALL_COMPANY_FOR_SHOW_IN_EQUIPMETNS } from './queries';

export interface FieldsInterface {
  value: string | Date | null;
  label: string;
  id: string;
  error: boolean;
  isDate?: boolean;
}

const FieldsConst: FieldsInterface[] = [
  {
    label: 'Введите сериный номер устройства',
    id: 'serialNumber',
    value: '',
    error: false,
  },
  {
    label: 'Контакты',
    id: 'contacts',
    value: '',
    error: false,
  },
  {
    label: 'Адрес установки',
    id: 'installationAdress',
    value: '',
    error: false,
  },
  {
    label: 'Гарантия до',
    id: 'guaranteePeriod',
    value: null,
    isDate: true,
    error: false,
  },
  {
    label: 'Период техническо поддержки',
    id: 'technicalSupportPeriod',
    value: null,
    isDate: true,
    error: false,
  },
  {
    label: 'Договор',
    id: 'serviceBasis',
    value: '',
    error: false,
  },
  {
    label: 'Дата установки',
    id: 'installationDate',
    value: null,
    isDate: true,
    error: false,
  },
  {
    label: 'Модификация',
    id: 'modification',
    value: '',
    error: false,
  },
  {
    label: 'Оператор',
    id: 'operator',
    value: '',
    error: false,
  },
  {
    label: 'Комплектация',
    id: 'complectation',
    value: '',
    error: false,
  },
];

const NewEquip: React.FC = () => {
  const [openModalWithAllGroups, setOpenModalWithAllGroups] = useState(false);

  const [equipmentsFields, setEquipmentsFields] = useState<FieldsInterface[]>(FieldsConst);
  const onChangeHandlerFieldsNameGroup = (value: string, id: string): void => {
    setEquipmentsFields(
      equipmentsFields.map(field => {
        if (field.id === id) field.value = value;
        return field;
      }),
    );
  };

  const [isWrongSnackBar, setIsWrongSnackBar] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const handlerOpenModalWindow = (): void => setOpenModalWithAllGroups(true);

  const handlerCloseModalWindow = (): void => setOpenModalWithAllGroups(false);

  const [companies, setCompanies] = React.useState<Company[]>();
  const [getAllCompanies, { data, loading: loadingCompany }] = useLazyQuery<{ companies: Company[] }>(
    GET_ALL_COMPANY_FOR_SHOW_IN_EQUIPMETNS,
  );

  const onFocusAutocomplete = async (): Promise<void> => {
    await getAllCompanies();
  };

  useEffect(() => {
    if (!loadingCompany) {
      setCompanies(data?.companies);
    }
  }, [data, loadingCompany]);

  const companyEmpty: Company[] = [
    {
      id: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      name: '',
      phone: '',
      email: '',
    },
  ];

  const [valueCurrentCompany, setValueCurrentCompany] = useState<Company>();
  const handleSelectCompany = (_e: React.SyntheticEvent<Element, Event>, value: string | null | Company): void => {
    if (value !== null && typeof value !== 'string') setValueCurrentCompany(value);
  };

  const { createOneEquipmentFunc, loadingCreateOneEquipment } = CreateOneEquipmentMutation();
  const clickAddNewEquip = async (): Promise<void> => {
    let isCanUpload = true;
    setEquipmentsFields(equipmentsFields =>
      equipmentsFields.map(field => {
        if (field.value === '') {
          field.error = true;
          isCanUpload = false;
        } else field.error = false;
        return field;
      }),
    );

    if (isCanUpload) {
      const newEquip: EquipmentCreateInput = {
        serialNumber: equipmentsFields[0].value,
        contacts: equipmentsFields[1].value,
        installationAdress: equipmentsFields[2].value,
        guaranteePeriod: equipmentsFields[3].value,
        technicalSupportPeriod: equipmentsFields[4].value,
        serviceBasis: equipmentsFields[5].value,
        installationDate: equipmentsFields[6].value,
        modification: equipmentsFields[7].value,
        operator: equipmentsFields[8].value,
        complectation: equipmentsFields[9].value,
        company: {
          connect: {
            id: valueCurrentCompany?.id,
          },
        },
      };
      await createOneEquipmentFunc(newEquip);
      setIsWrongSnackBar(false);
      setOpenSnackBar(true);
      handlerCloseModalWindow();
    } else {
      setOpenSnackBar(true);
      setIsWrongSnackBar(true);
    }
  };

  return (
    <Grid item>
      <AddButton handlerOpenModalWindow={handlerOpenModalWindow}>Добавить новое устройство</AddButton>
      <PortalModal
        open={openModalWithAllGroups}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Добавление нового устройства"
      >
        <Grid style={{ margin: 15, width: 500 }}>
          {FieldsConst.map(field => {
            return (
              <Field
                key={field.id}
                label={field.label}
                id={field.id}
                value={field.value}
                error={field.error}
                isDate={field.isDate}
                onChange={onChangeHandlerFieldsNameGroup}
              />
            );
          })}
          <Grid>
            <Autocomplete
              sx={{ width: 300 }}
              onOpen={onFocusAutocomplete}
              onChange={handleSelectCompany}
              value={valueCurrentCompany}
              isOptionEqualToValue={(company, value): boolean => company.id === value.id}
              getOptionLabel={(company): string => company.name}
              options={companies !== undefined ? companies : companyEmpty}
              loading={loadingCompany}
              renderInput={(params): JSX.Element => (
                <TextField
                  {...params}
                  label="Выберете компанию"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingCompany ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid style={{ marginTop: 10 }} container justifyContent="space-between">
            <Grid item>
              <Button onClick={handlerCloseModalWindow}>Отменить</Button>
            </Grid>
            <Grid item>
              <LoadingButton loading={loadingCreateOneEquipment} onClick={clickAddNewEquip} variant="outlined">
                Добавить
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </PortalModal>
      {isWrongSnackBar ? (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'error'}>
          Извините что-то пошло не так!
        </CustomSnackBar>
      ) : (
        <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'success'}>
          Новое устройство добавлено!
        </CustomSnackBar>
      )}
    </Grid>
  );
};

export default NewEquip;
