import { useLazyQuery, useQuery } from '@apollo/client';
import { Autocomplete, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Company, User, UserGroup } from '../../../Interfaces/interfaces';
import AutocompleteUserGroups from '../../AUTOCOMPLETEUSERGROUP/AutocompeteUserGroups';
import CustomSnackBar from '../../Helper/CustomSnackBar';
import CompanyGroup from './AddCompany/CompanyGroup';
import AddEquipmentInGroup from './AddEquipmentInGroup/AddEquipmentInGroup';
import AddNewEquipment from './AddNewEquipment/AddMewEquipment';
import AddNewGroup from './AddNewGroup';
import SaveButton from './ComponentsHelper/SaveButton';
import MultipleAutoCompleteCompany from './MultipleAutoCompleteCompany';
import { UpdateUserGroupMutation, UserGroupUpdateInput, UserWhereUniqueInput } from './mutation';
import { GET_ALL_USERS, GET_CURRENT_COMPANY_FOR_AUTOCOMPLETE, GET_CURRENT_USERS_FOR_GROUPS } from './queries';

export const companyEmptyObject: Company[] = [
  {
    id: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    name: '',
    phone: '',
    email: '',
  },
];

const emptyCurrentUser: User[] = [
  {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    login: '',
    role: 'TECHNICAL_SUPPORT',
  },
];

const Groput: React.FC = () => {
  const [isWrongSnackBar, setIsWrongSnackBar] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const { data, loading } = useQuery<{ users: User[] }>(GET_ALL_USERS);

  const [getCurrentCompaniesForGroup, { data: currentCompaniesData, loading: loadingCurrentCompaniesData }] =
    useLazyQuery<{ userGroup: UserGroup }>(GET_CURRENT_COMPANY_FOR_AUTOCOMPLETE);

  const [getCurrentUser, { data: currentUserData, loading: loadingUsers }] = useLazyQuery<{ userGroup: UserGroup }>(
    GET_CURRENT_USERS_FOR_GROUPS,
  );

  const [currentUsers, setCurrentUsers] = useState<User[]>();

  useEffect(() => {
    if (!loading) setCurrentUsers(data?.users);
  }, [data?.users, loading]);

  const [valueCurrentCompanies, setValueCurrentCompanies] = useState<(string | Company)[]>();
  const handleCurrentCompanies = (_e: React.SyntheticEvent<Element, Event>, value: (string | Company)[]): void => {
    setValueCurrentCompanies(value);
  };

  const [valueCurrentGroupUser, setValueCurrentGroupUser] = useState<UserGroup>();
  const handleSelectGroup = async (
    _e: React.SyntheticEvent<Element, Event>,
    value: string | null | UserGroup,
  ): Promise<void> => {
    if (value !== null && typeof value !== 'string') {
      setValueCurrentGroupUser(value);
      console.log('ad');
      await getCurrentCompaniesForGroup({ variables: { id: value.id } });
      await getCurrentUser({ variables: { id: value.id } });
    }
  };

  const [valueCurrentUsers, setValueCurrentUsers] = useState<(string | User)[]>([]);
  const handleCurrentUsers = (_e: React.SyntheticEvent<Element, Event>, value: (string | User)[]): void =>
    setValueCurrentUsers(value);

  useEffect(() => {
    if (!loadingUsers && currentUserData !== undefined) setValueCurrentUsers(currentUserData.userGroup.users);
  }, [currentUserData, loadingUsers]);

  useEffect(() => {
    if (!loadingCurrentCompaniesData && currentCompaniesData !== undefined) {
      setValueCurrentCompanies(currentCompaniesData.userGroup.companies);
    }
  }, [currentCompaniesData, loadingCurrentCompaniesData]);

  const { updateUserGroupFunc, loadingUpdateGroup } = UpdateUserGroupMutation();

  const clickSaveChanges = async (): Promise<void> => {
    if (
      valueCurrentGroupUser !== undefined &&
      valueCurrentGroupUser?.id !== undefined &&
      valueCurrentUsers !== undefined &&
      valueCurrentGroupUser !== undefined
    ) {
      const createNewArray: UserWhereUniqueInput[] = valueCurrentUsers.map(user => {
        return { id: typeof user !== 'string' ? user.id : '' };
      });

      const createArrayWithNewCompanies: UserWhereUniqueInput[] = valueCurrentCompanies!.map(company => {
        return { id: typeof company !== 'string' ? company.id : '' };
      });

      const createFinalyNewArray: UserGroupUpdateInput = {
        users: { set: createNewArray },
        companies: { set: createArrayWithNewCompanies },
      };

      await updateUserGroupFunc(createFinalyNewArray, valueCurrentGroupUser.id);
      setOpenSnackBar(true);
      setIsWrongSnackBar(false);
    } else {
      setIsWrongSnackBar(true);
      setOpenSnackBar(true);
    }
  };

  return (
    <Grid container direction="column" spacing={5}>
      <Grid container item>
        <Paper style={{ padding: 30 }} elevation={3}>
          <Grid spacing={3} container>
            <Grid xs={7} item>
              <Typography style={{ marginBottom: 20 }} variant="h5">
                Добавить пользователя и компанию в группу
              </Typography>
              <Grid container direction="column">
                <Grid xs item style={{ marginBottom: 20 }}>
                  <AutocompleteUserGroups
                    handlerChangeCurrentGroups={handleSelectGroup}
                    currentGroup={valueCurrentGroupUser}
                  />
                </Grid>
                <>
                  <Grid xs item style={{ marginBottom: 20 }}>
                    <Autocomplete
                      freeSolo
                      size="small"
                      multiple
                      disabled={valueCurrentGroupUser === undefined ? true : false}
                      value={valueCurrentUsers}
                      onChange={handleCurrentUsers}
                      options={currentUsers === undefined ? emptyCurrentUser : currentUsers}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      getOptionLabel={(option: User): string =>
                        `${option.login} (${option.firstname} ${option.lastname}) [${option.role}]`
                      }
                      renderInput={(params): JSX.Element => (
                        <TextField
                          {...params}
                          disabled={valueCurrentGroupUser === undefined ? true : false}
                          label="Выберете пользователей"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs item style={{ marginBottom: 20 }}>
                    <MultipleAutoCompleteCompany
                      valueCurrentGroupUser={valueCurrentGroupUser}
                      handleCurrentCompanies={handleCurrentCompanies}
                      valueCurrentCompanies={valueCurrentCompanies}
                    />
                  </Grid>
                </>
              </Grid>
              <Grid direction="row-reverse" spacing={1} container justifyContent="space-between">
                <Grid item>
                  <SaveButton clickSaveChanges={clickSaveChanges} loading={loadingUpdateGroup} />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={5} item>
              <AddNewGroup />
            </Grid>
          </Grid>
        </Paper>
        {isWrongSnackBar ? (
          <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'error'}>
            Извините что-то пошло не так!
          </CustomSnackBar>
        ) : (
          <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity={'success'}>
            Изменения сохранены
          </CustomSnackBar>
        )}
      </Grid>
      {/* <Grid item>
        <AddInComapnyEquipments />
      </Grid> */}
      <Grid item>
        <AddEquipmentInGroup />
      </Grid>
      <Grid item>
        <AddNewEquipment />
      </Grid>
      <Grid item>
        <CompanyGroup />
      </Grid>
    </Grid>
  );
};

export default Groput;
