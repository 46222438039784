import { gql } from '@apollo/client';

export const GET_ALL_EQUIPMENTS = gql`
  query {
    getAllEquipment {
      serialNumber
      lastSessionDateTime
      company {
        id
        name
      }
      lines {
        id
        currentStatus {
          status
          id
          createdAt
        }
      }
    }
  }
`;

export const GET_ALL_MY_EQUIPMENTS = gql`
  query {
    getAllMyEquipment {
      serialNumber
      lastSessionDateTime
      company {
        id
        name
      }
      lines {
        id
        currentStatus {
          status
          id
          createdAt
        }
      }
    }
  }
`;

export const ME_QUERY = gql`
  query {
    me {
      id
      firstname
      lastname
      email
      login
      role
    }
  }
`;

export const GET_ONE_EQUIPMENT = gql`
  query ($serialNumber: String!) {
    getOneEquipment(data: { equipmentSerialNumber: $serialNumber }) {
      serialNumber
      contacts
      installationAdress
      guaranteePeriod
      technicalSupportPeriod
      serviceBasis
      comments
      installationDate
      checkDate
      coordinates
      subscriptionServiceDateTo
      subscriptionServiceDateFrom
      operator
      modification
      complectation
      bitrixId
      acuracyGrade {
        name
      }
      checkSertificateNumber
      erectors {
        id
        firstname
        lastname
        installedEquipment {
          serialNumber
          company {
            name
          }
        }
      }
      lines {
        id
        relatedEquipment {
          serialNumber
        }
        currentStatus {
          id
          status
          createdAt
        }
      }
      company {
        id
        createdAt
        updatedAt
        name
        phone
        email
        bitrixID
      }
    }
  }
`;

export const GET_LINE_STATUSES_EQUIPMENT = gql`
  query ($serialNumber: String!) {
    getOneEquipment(data: { equipmentSerialNumber: $serialNumber }) {
      lines {
        id
        currentStatus {
          id
          status
          createdAt
        }
        statusLog {
          id
          createdAt
          status
        }
      }
    }
  }
`;
