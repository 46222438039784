import { useMutation, gql } from '@apollo/client';
import { LinesStatusLog } from '../../../Interfaces/interfaces';

interface EraseErrorInput {
  lineId: string;
}

export const EraseErrorMutation = () => {
  const [EraseErrors, { loading }] = useMutation<{ EraseErrors: LinesStatusLog }>(gql`
    mutation ($data: EraseErrorsArgs!) {
      EraseErrors(data: $data) {
        id
      }
    }
  `);
  const EraseErrorsFunc = (data: EraseErrorInput): Promise<unknown> => EraseErrors({ variables: { data } });
  return { EraseErrorsFunc, LoadingEraseErrors: loading };
};
