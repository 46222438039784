import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, MenuItem, Select, SelectChangeEvent, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Colors } from '../../../../Const/Colors';
import { ROLE, User } from '../../../../Interfaces/interfaces';
import CustomSnackBar from '../../../Helper/CustomSnackBar';
import PortalModal from '../../../Helper/PortalModal';
import DeleteUserModal from './DeleteUserModal';
import { UpdateOneUserRoleInterface, UpdateOneUserRoleMutation } from './mutation';
interface StatusConditionInterface {
  title: string;
  value: ROLE;
}

const statusCondition: StatusConditionInterface[] = [
  {
    title: 'Админ',
    value: 'ADMINISTRATOR',
  },
  {
    title: 'Клиент',
    value: 'CLIENT',
  },
  {
    title: 'Тех. поддержка',
    value: 'TECHNICAL_SUPPORT',
  },
];

interface OneUserTableProps {
  user: User;
  refetch(): void;
}

const OneUserTable: React.FC<OneUserTableProps> = ({ user, refetch }) => {
  const [currentRole, setCurrentRole] = useState<ROLE>(user.role);

  useEffect(() => {
    setCurrentRole(user.role);
  }, [user]);

  const { updateOneUserRoleFunc, loadingUpdateOneUserRole } = UpdateOneUserRoleMutation();
  const handlerChangeStatusNotification = async (event: SelectChangeEvent): Promise<void> => {
    setCurrentRole(event.target.value as ROLE);

    const newUpdateUserRole: UpdateOneUserRoleInterface = {
      role: { set: event.target.value as ROLE },
    };

    await updateOneUserRoleFunc(newUpdateUserRole, user.id);
  };

  const [currentUserForDelete, setCurrentUserForDelete] = useState<User>();
  const [openModalWithAllUsers, setOpenModalWithAllUsers] = useState(false);
  const handlerOpenModalWindow = (user: User): void => {
    setOpenModalWithAllUsers(true);
    setCurrentUserForDelete(user);
  };

  const handlerCloseModalWindow = (): void => setOpenModalWithAllUsers(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
    refetch();
  };

  const handleOpenSnackbar = (): void => setOpenSnackBar(true);

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align="left">{user.login}</TableCell>
      <TableCell align="left">
        {user.firstname} {user.lastname}{' '}
        {user.middleName !== null && user.middleName !== undefined && `${user.middleName}`}
      </TableCell>
      <TableCell style={{ maxWidth: 95 }} align="left">
        <Select
          fullWidth
          size="small"
          disabled={loadingUpdateOneUserRole}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={user.role}
          value={currentRole}
          onChange={(event: SelectChangeEvent): Promise<void> => handlerChangeStatusNotification(event)}
        >
          {statusCondition.map((cond, index) => {
            return (
              <MenuItem key={index} value={cond.value}>
                {cond.title}
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell align="left">
        <IconButton onClick={(): void => handlerOpenModalWindow(user)} color="error" aria-label="Удалить группу">
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <PortalModal
        open={openModalWithAllUsers}
        handlerCloseModalWindow={handlerCloseModalWindow}
        dialogTitle="Удаление пользователя"
      >
        <DeleteUserModal
          handleOpenSnackBar={handleOpenSnackbar}
          handlerCloseModalWindow={handlerCloseModalWindow}
          user={currentUserForDelete!}
          handlerCloseMainWindow={handlerCloseModalWindow}
        />
      </PortalModal>
      <CustomSnackBar openSnackBar={openSnackBar} handleCloseSnackBar={handleCloseSnackBar} severity="success">
        Пользователь <b style={{ color: Colors.statusColor.pure.red }}>{currentUserForDelete?.login}</b> был удален!
      </CustomSnackBar>
    </TableRow>
  );
};

export default OneUserTable;
